<template>
  <div>
    <div class="sidebar_container">
      <div class="sidebar_header">
        <CRow class="m-0">
          <CCol cols="10" lg="10" md="10" sm="10" class="text-left">
            <span v-if="history.length > 1" class="pointer" @click="removeSidebarHistoryStep()">
              <i class="fas fa-arrow-circle-left mr-1"/>
            </span>
            <span class="sidebar_subject">
              {{eventTypeIdExternal ? $t('communications.Edit_moment') : $t('communications.New_moment')}}
            </span>
          </CCol>
          <CCol cols="2" lg="2" md="2" sm="2" class="text-right">
            <span @click="closeSidebarRight();"><i class="fas fa-times"/></span>    
          </CCol>      
        </CRow>
      </div>
      <div class="sidebar_content">
        <CRow v-if="moment.custom_event === 1" class="m-0">
          <CCol cols="6" lg="6" class="pt-0">
            <CInput type="text" :label="$t('communications.Moment_name')" class="mb-0" v-model="moment.description" required was-validated/>
          </CCol>
          <CCol cols="6" lg="6" class="pt-0">
            <CInput type="text" :label="$t('communications.Moment_tag')" class="mb-0" v-model="moment.tag" required was-validated/>
          </CCol>         
        </CRow>
        <!-- Icon -->
        <CRow class="m-0">
          <!-- <CCol cols="6" lg="6" class="pt-0">
            <label>
              <span>{{$t('communications.Upload_moment_icon')}}</span>
            </label>
            <image-uploader class="image_upload" :preview="true" :className="['fileinput', { 'fileinput--loaded': momentImageUploaded }]" capture="environment" :debug="0" doNotResize="gif" :autoRotate="true" outputFormat="verbose" @input="setMomentImage">
              <label for="fileInput" slot="upload-label" class="m-0">
                <span class="upload-caption btn btn-primary m-0"><i class="fas fa-file-upload mr-1"/>{{momentImageUploaded ? $t('common.Replace_image') : $t('common.Upload_icon')}}</span>
              </label>
            </image-uploader>
          </CCol> -->
          <CCol v-if="eventTypeIdExternal && moment.event_type_image_id && moment.custom_event === 1" cols="12" lg="12" class="pt-0">
            <label>{{$t('common.Current_icon')}}</label>
            <img v-if="moment.custom_event === 0" :src="apiBaseUrl + '/v1/common/cdn/file/image/core-auto-moment-icon/global/' + moment.event_type_image_id + '/' + clientToken" class="current_image"/>
            <img v-if="moment.custom_event === 1" :src="apiBaseUrl + '/v1/common/cdn/file/image/core-auto-moment-custom/' + moment.tag + '/' + moment.event_type_image_id + '/' + clientToken" class="current_image"/>
          </CCol>          
        </CRow>
        <!-- Countries -->
        <CRow v-if="moment.custom_event === 1" class="m-0">
          <CCol cols="12" lg="12" class="pt-0 locale-changer">
            <label>{{$t('communications.Moment_countries')}}</label>
            <multiselect class="open_absolute"
                         v-model="moment.countries" 
                         :options="countries" 
                         :multiple="true"
                         :hideSelected="false"
                         :closeOnSelect="true"
                         track-by="country_id_external" 
                         label="name"
                         :show-labels="false">
              <span slot="noResult">{{$t('common.no_countries_found')}}</span>

              <template slot="singleLabel" slot-scope="country">
                <div class="d-flex align-items-center">
                  <span v-if="country.option.flag" class="language_flag" v-html="getTwemoji(country.option.flag)"></span>
                  <span class="language_name">{{ country.option.name }}</span>
                </div>
              </template>

              <template slot="option" slot-scope="country">
                <div class="d-flex align-items-center">
                  <span v-if="country.option.flag" class="language_flag" v-html="getTwemoji(country.option.flag)"></span>
                  <span class="language_name">{{ country.option.name }}</span>
                </div>
              </template>
            </multiselect>
          </CCol>
        </CRow>        
        <!-- General -->
        <CRow class="m-0">
          <CCol cols="6" lg="6" class="pt-0">
            <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="moment.is_active" size="is-small">{{ $t('communications.Moment_active') }}</b-switch>
          </CCol>
          <CCol cols="6" lg="6" class="pt-0">
            <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="moment.is_required" size="is-small">{{ $t('communications.Moment_required') }}</b-switch>
          </CCol>         
        </CRow>
        <!-- Email -->
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <span><b>{{$t('common.Email')}}</b></span>            
          </CCol>
          <CCol cols="6" lg="6" class="pt-0">
            <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="moment.email_is_available" size="is-small">{{ $t('communications.Moment_email_available') }}</b-switch>
          </CCol>
          <CCol cols="6" lg="6" class="pt-0">
            <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="moment.email_is_required" size="is-small">{{ $t('communications.Moment_email_required') }}</b-switch>
          </CCol>
          <CCol cols="6" lg="6" class="pt-0">
            <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="moment.email_is_active" size="is-small">{{ $t('communications.Moment_email_active') }}</b-switch>
          </CCol>
          <CCol v-if="moment.custom_event === 1" cols="12" lg="12" class="pt-0">
            <label>{{$t('communications.Email_content_tags')}}</label>
            <div class="meta mb-2">
              <span class="mr-1">{{$t('communications.Tags_currently_supported')}}</span>
              <span v-if="productTag === 'loyalty'">{$platform_name}, {$email_signatory}, {$name}, {$points}</span>
              <span v-else>{$platform_name}, {$email_signatory}, {$name}</span>
              <span @click="copyToClipboard('email', productTag);" class="pointer ml-1">
                <i class="fas fa-copy"/>
              </span>
            </div>
            <CInput type="text"
                    v-model="moment.email_content_tags"
                    :required="moment.email_is_available && moment.email_is_active"
                    :was-validated="moment.email_is_available && moment.email_is_active"
                    class="mb-0">
            </CInput>
          </CCol>          
        </CRow>
        <!-- Loyalty points -->
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <span><b>{{$t('common.Coins')}}</b></span>            
          </CCol>
          <CCol cols="6" lg="6" class="pt-0">
            <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="moment.points_are_available" size="is-small">{{ $t('communications.Moment_points_available') }}</b-switch>
          </CCol>
          <CCol cols="6" lg="6" class="pt-0">
            <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="moment.points_are_active" size="is-small">{{ $t('communications.Moment_points_active') }}</b-switch>
          </CCol>
          <CCol v-if="moment.points_are_available && moment.points_are_active" cols="12" lg="12" class="pt-0">
            <CInput type="number" 
                    v-model="moment.points"
                    :label="productTag !== 'loyalty' ? $t('common.Coints_received_for_moment') : $t('sm.Coints_received_for_spark')"
                    :required="moment.points_are_available && moment.points_are_active"
                    :was-validated="moment.points_are_available && moment.points_are_active"
                    class="mb-0">
            </CInput>                    
          </CCol>
        </CRow>
        <!-- Send on date -->
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <span><b>{{$t('communications.Send_on_date')}}</b></span>            
          </CCol>
          <CCol cols="6" lg="6" class="pt-0">
            <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="moment.send_on_date_is_available" size="is-small">{{ $t('communications.Moment_send_on_date_available') }}</b-switch>
          </CCol>
          <CCol cols="6" lg="6" class="pt-0">
            <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="moment.send_on_date_is_required" size="is-small">{{ $t('communications.Moment_send_on_date_required') }}</b-switch>
          </CCol>
          <CCol cols="6" lg="6" class="pt-0">
            <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="moment.send_on_date_is_active" size="is-small">{{ $t('communications.Moment_send_on_date_active') }}</b-switch>
          </CCol>          
          <CCol v-if="moment.send_on_date_is_available && moment.send_on_date_is_active" cols="12" lg="12" class="pt-0">
            <label>{{$t('communications.Send_communication_on_date')}}</label>
            <b-datetimepicker v-model="moment.send_on_date" :placeholder="$t('common.click_to_select')" editable :datepicker="datepickerOptions" :timepicker="timepickerOptions"></b-datetimepicker>
          </CCol>
        </CRow>

        <b-collapse class="panel show m-0" :open="false" position="is-bottom">
          <template #trigger="props">
            <span :aria-expanded="props.open">{{!props.open ? $t('common.Show_more') : $t('common.Show_less')}}</span>
          </template>
          <div class="panel-block p-0">
            <!-- Connect posts -->
            <CRow class="m-0">
              <CCol cols="12" lg="12" class="pt-0">
                <span><b>{{$t('communications.Connect_posts')}}</b></span>            
              </CCol>
              <CCol cols="6" lg="6" class="pt-0">
                <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="moment.sw_post_is_available" size="is-small">{{ $t('communications.Moment_sw_post_available') }}</b-switch>
              </CCol>
              <CCol cols="6" lg="6" class="pt-0">
                <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="moment.sw_post_is_required" size="is-small">{{ $t('communications.Moment_sw_post_required') }}</b-switch>
              </CCol>
              <CCol cols="6" lg="6" class="pt-0">
                <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="moment.sw_post_is_active" size="is-small">{{ $t('communications.Moment_sw_post_active') }}</b-switch>
              </CCol>
              <CCol cols="6" lg="6" class="pt-0">
                <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="moment.images_available" size="is-small">{{ $t('communications.Moment_has_images') }}</b-switch>
              </CCol>          
              <CCol v-if="moment.custom_event === 1" cols="12" lg="12" class="pt-0">
                <label>{{$t('communications.Post_content_tags')}}</label>
                <div class="meta mb-2">
                  <span class="mr-1">{{$t('communications.Tags_currently_supported')}}</span>
                  <span>[NAME]</span>
                  <span @click="copyToClipboard('post', productTag);" class="pointer ml-1">
                    <i class="fas fa-copy"/>
                  </span>                      
                </div>
                <CInput type="text"
                        v-model="moment.sw_post_content_tags"
                        :required="moment.sw_post_is_available && moment.sw_post_is_active"
                        :was-validated="moment.sw_post_is_available && moment.sw_post_is_active"                        
                        class="mb-0">
                </CInput>                                                  
              </CCol>
            </CRow>
            <!-- Push notifications -->
            <CRow class="m-0">
              <CCol cols="12" lg="12" class="pt-0">
                <span><b>{{$t('communications.Push_notifications')}}</b></span>            
              </CCol>
              <CCol cols="6" lg="6" class="pt-0">
                <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="moment.push_is_available" size="is-small">{{ $t('communications.Moment_push_available') }}</b-switch>
              </CCol>
              <CCol cols="6" lg="6" class="pt-0">
                <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="moment.push_is_required" size="is-small">{{ $t('communications.Moment_push_required') }}</b-switch>
              </CCol>
              <CCol cols="6" lg="6" class="pt-0">
                <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="moment.push_is_active" size="is-small">{{ $t('communications.Moment_push_active') }}</b-switch>
              </CCol>
            </CRow>
            <!-- Send after days -->
            <CRow class="m-0">
              <CCol cols="12" lg="12" class="pt-0">
                <span><b>{{$t('communications.Send_after_days')}}</b></span>            
              </CCol>
              <CCol cols="6" lg="6" class="pt-0">
                <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="moment.send_after_days_is_available" size="is-small">{{ $t('communications.Moment_send_after_days_available') }}</b-switch>
              </CCol>
              <CCol cols="6" lg="6" class="pt-0">
                <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="moment.send_after_days_is_required" size="is-small">{{ $t('communications.Moment_send_after_days_required') }}</b-switch>
              </CCol>
              <CCol cols="6" lg="6" class="pt-0">
                <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="moment.send_after_days_is_active" size="is-small">{{ $t('communications.Moment_send_after_days_active') }}</b-switch>
              </CCol>          
              <CCol v-if="moment.send_after_days_is_available && moment.send_after_days_is_active" cols="12" lg="12" class="pt-0">
                <CInput type="text"
                        v-model="moment.send_after_days"
                        :label="$t('communications.Send_moment_communication_after_days')" 
                        :required="moment.send_after_days_is_available && moment.send_after_days_is_active"
                        :was-validated="moment.send_after_days_is_available && moment.send_after_days_is_active"
                        class="mb-0">
                 </CInput>
              </CCol>
            </CRow>
          </div>
        </b-collapse>
      </div>
      <div class="sidebar_footer">
        <CRow class="m-0">
          <CCol cols="12" lg="12">
            <div v-if="!eventTypeIdExternal">
              <CButton color="primary" @click="insertMoment();"><i class="fas fa-check mr-1"/>{{ $t('Save') }}</CButton>
              <CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{ $t('Dismiss') }}</CButton>
            </div>
            <div v-else>
              <CButton color="primary" @click="updateMoment();"><i class="fas fa-check mr-1"/>{{$t('Update')}}</CButton>
              <CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{ $t('Dismiss') }}</CButton>
            </div>             

          </CCol>
        </CRow>    
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect'
import QuickEdit from 'vue-quick-edit';
import ImageUploader from 'vue-image-upload-resize';

export default {
  name: 'productAutoMomentDetails',
  props: ['history', 'eventTypeIdExternal', 'productTag'],
  watch: {
    $props: {
      handler() {
        this.getMomentDetails();
      },
      deep: true,
      immediate: true,
    }
  },  
  components: {
    Multiselect,
    QuickEdit,
    ImageUploader
  },
  data() {
    return {
      apiBaseUrl: null,
      clientToken: null,      
      moment: {},
      momentImageUploaded: false,
      countries: [],
      datepickerOptions: {
        yearsRange: [0, 10],
        firstDayOfWeek: 1,
        showWeekNumber: true
      },
      timepickerOptions: {
        incrementHours: 1,
        incrementMinutes: 15
      }
    }
  },
  methods: {
    getMomentDetails() {
      if(this.eventTypeIdExternal !== null) {
        axios.get(process.env.VUE_APP_API_URL + '/v1/core/event/' + this.productTag + '/' + this.eventTypeIdExternal)
        .then(res => {
          this.moment = res.data.data;

          if(this.moment.send_on_date_is_available && this.moment.send_on_date_is_active) {
            this.moment.send_on_date = new Date(this.moment.send_on_date);
          }          
        })
        .catch(err => {
          console.error(err); 
        });                  
      } else {
        // Reset the subtopic data
        this.resetMomentData();
      }
    },    
    insertMoment() {
      let params = {};
      params.momentData = this.moment;
      (params.momentData.is_active === true) ? params.momentData.active = 1 : params.momentData.active = 0;
      (params.momentData.is_required === true) ? params.momentData.required = 1 : params.momentData.required = 0;
      (params.momentData.email_is_active === true) ? params.momentData.email_active = 1 : params.momentData.email_active = 0;
      (params.momentData.email_is_available === true) ? params.momentData.email_available = 1 : params.momentData.email_available = 0;
      (params.momentData.email_is_required === true) ? params.momentData.email_required = 1 : params.momentData.email_required = 0;
      (params.momentData.sw_post_is_active === true) ? params.momentData.sw_post_active = 1 : params.momentData.sw_post_active = 0;
      (params.momentData.sw_post_is_available === true) ? params.momentData.sw_post_available = 1 : params.momentData.sw_post_available = 0;
      (params.momentData.sw_post_is_required === true) ? params.momentData.sw_post_required = 1 : params.momentData.sw_post_required = 0;        
      (params.momentData.push_is_active === true) ? params.momentData.push_active = 1 : params.momentData.push_active = 0;
      (params.momentData.push_is_available === true) ? params.momentData.push_available = 1 : params.momentData.push_available = 0;
      (params.momentData.push_is_required === true) ? params.momentData.push_required = 1 : params.momentData.push_required = 0;
      (params.momentData.images_available === true) ? params.momentData.has_images = 1 : params.momentData.has_images = 0;  
      (params.momentData.points_are_active === true) ? params.momentData.points_active = 1 : params.momentData.points_active = 0;
      (params.momentData.points_are_available === true) ? params.momentData.points_available = 1 : params.momentData.points_available = 0;
      (params.momentData.send_after_days_is_active === true) ? params.momentData.send_after_days_active = 1 : params.momentData.send_after_days_active = 0;
      (params.momentData.send_after_days_is_available === true) ? params.momentData.send_after_days_available = 1 : params.momentData.send_after_days_available = 0;
      (params.momentData.send_after_days_is_required === true) ? params.momentData.send_after_days_required = 1 : params.momentData.send_after_days_required = 0;
      (params.momentData.send_on_date_is_active === true) ? params.momentData.send_on_date_active = 1 : params.momentData.send_on_date_active = 0;
      (params.momentData.send_on_date_is_available === true) ? params.momentData.send_on_date_available = 1 : params.momentData.send_on_date_available = 0;
      (params.momentData.send_on_date_is_required === true) ? params.momentData.send_on_date_required = 1 : params.momentData.send_on_date_required = 0;

      if(!params.momentData.email_is_available && !params.momentData.email_is_active) {
        params.momentData.email_content_tags = null;
      }

      if(!params.momentData.sw_post_is_available && !params.momentData.sw_post_is_active) {
        params.momentData.sw_post_content_tags = null;
      }      

      if(!params.momentData.points_are_available && !params.momentData.points_are_active) {
        params.momentData.points = null;
      }

      if(!params.momentData.send_after_days_is_available && !params.momentData.send_after_days_is_active) {
        params.momentData.send_after_days = null;
      }

      if(!params.momentData.send_on_date_is_available && !params.momentData.send_on_date_is_active) {
        params.momentData.send_on_date = null;
      }

      if(params.momentData.send_on_date) {
        params.momentData.event_send_on_date = this.$luxon(params.momentData.send_on_date.toISOString(), "yyyy-MM-dd HH:mm:ss");
      } else {
        params.momentData.event_send_on_date = null;
      }
      
      if(this.validateMoment(params) === true) {
        axios.post(process.env.VUE_APP_API_URL + '/v1/core/event/' + this.productTag + '/moment', params)
        .then(res => {
          // Show a success notice
          this.$buefy.toast.open({ message: this.$t('communications.Moment_added'), type: 'is-success', duration: 3000 });
          // Emit the custom_moment_updated event based on the product tag
          this.$bus.$emit(this.productTag + '_moment_added');
          // Reset the data
          this.resetMomentData();
          // Close the sidebar
          this.closeSidebarRight();          
        })
        .catch(err => {
          if(err.response.data.code === 'Doublure Event Tag') {
            this.$buefy.toast.open({ message: this.$t('communications.Moment_tag_already_exists'), type: 'is-danger', duration: 2000 });
          } else {
            this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
          }
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }
    },
    updateMoment() {
      let params = {};
      params.momentData = this.moment;
      (params.momentData.is_active === true) ? params.momentData.active = 1 : params.momentData.active = 0;
      (params.momentData.is_required === true) ? params.momentData.required = 1 : params.momentData.required = 0;
      (params.momentData.email_is_active === true) ? params.momentData.email_active = 1 : params.momentData.email_active = 0;
      (params.momentData.email_is_available === true) ? params.momentData.email_available = 1 : params.momentData.email_available = 0;
      (params.momentData.email_is_required === true) ? params.momentData.email_required = 1 : params.momentData.email_required = 0;
      (params.momentData.sw_post_is_active === true) ? params.momentData.sw_post_active = 1 : params.momentData.sw_post_active = 0;
      (params.momentData.sw_post_is_available === true) ? params.momentData.sw_post_available = 1 : params.momentData.sw_post_available = 0;
      (params.momentData.sw_post_is_required === true) ? params.momentData.sw_post_required = 1 : params.momentData.sw_post_required = 0;        
      (params.momentData.push_is_active === true) ? params.momentData.push_active = 1 : params.momentData.push_active = 0;
      (params.momentData.push_is_available === true) ? params.momentData.push_available = 1 : params.momentData.push_available = 0;
      (params.momentData.push_is_required === true) ? params.momentData.push_required = 1 : params.momentData.push_required = 0;
      (params.momentData.images_available === true) ? params.momentData.has_images = 1 : params.momentData.has_images = 0;  
      (params.momentData.points_are_active === true) ? params.momentData.points_active = 1 : params.momentData.points_active = 0;
      (params.momentData.points_are_available === true) ? params.momentData.points_available = 1 : params.momentData.points_available = 0;
      (params.momentData.send_after_days_is_active === true) ? params.momentData.send_after_days_active = 1 : params.momentData.send_after_days_active = 0;
      (params.momentData.send_after_days_is_available === true) ? params.momentData.send_after_days_available = 1 : params.momentData.send_after_days_available = 0;
      (params.momentData.send_after_days_is_required === true) ? params.momentData.send_after_days_required = 1 : params.momentData.send_after_days_required = 0;
      (params.momentData.send_on_date_is_active === true) ? params.momentData.send_on_date_active = 1 : params.momentData.send_on_date_active = 0;
      (params.momentData.send_on_date_is_available === true) ? params.momentData.send_on_date_available = 1 : params.momentData.send_on_date_available = 0;
      (params.momentData.send_on_date_is_required === true) ? params.momentData.send_on_date_required = 1 : params.momentData.send_on_date_required = 0;

      if(!params.momentData.email_is_available && !params.momentData.email_is_active) {
        params.momentData.email_content_tags = null;
      }

      if(!params.momentData.sw_post_is_available && !params.momentData.sw_post_is_active) {
        params.momentData.sw_post_content_tags = null;
      }      

      if(!params.momentData.points_are_available && !params.momentData.points_are_active) {
        params.momentData.points = null;
      }

      if(!params.momentData.send_after_days_is_available && !params.momentData.send_after_days_is_active) {
        params.momentData.send_after_days = null;
      }

      if(!params.momentData.send_on_date_is_available && !params.momentData.send_on_date_is_active) {
        params.momentData.send_on_date = null;
      }

      if(params.momentData.send_on_date) {
        params.momentData.event_send_on_date = this.$luxon(params.momentData.send_on_date.toISOString(), "yyyy-MM-dd HH:mm:ss");
      }

      if(this.validateMoment(params) === true) {
        axios.put(process.env.VUE_APP_API_URL + '/v1/core/event/' + this.productTag + '/' + this.eventTypeIdExternal + '/config', params)
        .then(res => {
          // Show a success notice
          this.$buefy.toast.open({ message: this.$t('communications.Moment_config_updated'), type: 'is-success', duration: 3000 });
          // Emit the moment_config_updated event based on the product tag
          this.$bus.$emit(this.productTag + '_moment_config_updated');
          // Reset the data
          this.resetMomentData();
          // Close the sidebar
          this.closeSidebarRight();          
        })
        .catch(err => {
          if(err.response.data.code === 'Doublure Event Tag') {
            this.$buefy.toast.open({ message: this.$t('communications.Moment_tag_already_exists'), type: 'is-danger', duration: 2000 });
          } else {
            this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
          }
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }
    },
    validateMoment(params) {
      let description = params.momentData.description;
      let tag = params.momentData.tag;
      // let image = params.momentData.image;
      let event_type_image_id = params.momentData.event_type_image_id;
      let email_is_active = params.momentData.email_is_active;
      let email_is_available = params.momentData.email_is_available;
      let email_content_tags = params.momentData.email_content_tags;
      let sw_post_is_active = params.momentData.sw_post_is_active;
      let sw_post_is_available = params.momentData.sw_post_is_available;
      let sw_post_content_tags = params.momentData.sw_post_content_tags;
      let points = params.momentData.points;
      let points_are_active = params.momentData.points_are_active;
      let points_are_available = params.momentData.points_are_available;
      let send_after_days = params.momentData.send_after_days;
      let send_after_days_is_active = params.momentData.send_after_days_is_active;
      let send_after_days_is_available = params.momentData.send_after_days_is_available;
      let send_on_date = params.momentData.send_on_date;
      let send_on_date_is_active = params.momentData.send_on_date_is_active;
      let send_on_date_is_available = params.momentData.send_on_date_is_available;

      // Check if base information for the moment is available
      if(description && tag) {
        // Check if the icon is set for the moment
        // if((!this.eventTypeIdExternal && !image) || (this.eventTypeIdExternal && !event_type_image_id)) {
        //   return false;
        // }
        // Check if the config settings are set correctly
        if((email_is_available && email_is_active && !email_content_tags) || 
           (sw_post_is_available && sw_post_is_active && !sw_post_content_tags) ||
           (points_are_available && points_are_active && !points) ||
           (send_after_days_is_available && send_after_days_is_active && !send_after_days) ||
           (send_on_date_is_available && send_on_date_is_active && !send_on_date)) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    getCountries() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/common/countries/multiselect')
      .then(res => {      
        this.countries = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },  
    resetMomentData() {
      this.moment = {
        description: null,
        tag: null,
        image: null,
        event_type_image_id: null,
        countries: [],
        is_active: true,
        is_required: false,
        email_is_active: true,
        email_is_required: true,
        email_is_available: true,
        email_content_tags: '{$platform_name}, {$email_signatory}, {$name}, {$points}',
        sw_post_is_active: false,
        sw_post_is_required: false,
        sw_post_is_available: false,
        sw_post_content_tags: null,
        push_is_active: false,
        push_is_required: false,
        push_is_available: false,
        images_available: false,
        points: 50,
        points_description: null,
        points_are_active: true,
        points_are_available: true,
        send_after_days: null,
        send_after_days_is_active: false,
        send_after_days_is_required: false,
        send_after_days_is_available: false,
        send_on_date: null,
        send_on_date_is_active: true,
        send_on_date_is_required: true,
        send_on_date_is_available: true
      };
    },
    setMomentImage(output) {
      this.momentImageUploaded = true;
      this.moment.image = output.dataUrl;
    },    
    copyToClipboard(source, productTag) {
      let copyValue = "";

      if(source === 'email') {
        if(productTag === 'loyalty') copyValue = "{$platform_name}, {$email_signatory}, {$name}, {$points}";
      } else if(source === 'post') {
        if(productTag === 'loyalty') copyValue = "{$name}";
      }
      
      // Create temporary textarea for copy
      var copy = document.createElement("textarea");
      document.body.appendChild(copy);
      copy.value = copyValue;
      copy.select();
      document.execCommand("copy");
      document.body.removeChild(copy);
    },    
    getTwemoji(emoji) {
      return twemoji.parse(emoji);
    }
  },
  mounted: function() {
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');

    this.resetMomentData();
    this.getCountries();
  }  
}
</script>