export default [
  "Regular one-on-ones boost employee satisfaction and productivity.",
  "Flexible work hours can increase employee retention by 77%.",
  "Recognition programs can reduce voluntary turnover by 31%.",
  "Mentorship programs improve employee engagement and career development.",
  "Open-door policies foster trust and improve communication.",
  "Continuous feedback is more effective than annual performance reviews.",
  "Diversity in leadership roles increases company profitability by 35%.",
  "Employee wellness programs reduce absenteeism by up to 56%.",
  "Clear career paths increase employee motivation and loyalty.",
  "Peer-to-peer recognition programs boost team morale and cohesion.",
  "Remote work options can increase productivity by 13%.",
  "Regular team-building activities improve collaboration and communication skills.",
  "Transparent communication about company goals increases employee engagement.",
  "Offering professional development opportunities reduces turnover by 34%.",
  "Personalized onboarding programs improve new hire retention by 82%.",
  "Employee volunteer programs boost job satisfaction and company loyalty.",
  "Providing standing desks can increase productivity by 46%.",
  "Encouraging micro-breaks improves focus and reduces stress levels.",
  "Implementing a buddy system helps new hires integrate faster.",
  "Celebrating small wins boosts team morale and motivation.",
  "Offering sabbaticals can rejuvenate long-term employees and prevent burnout.",
  "Creating a culture of psychological safety increases innovation.",
  "Providing ergonomic workstations reduces work-related injuries by 59%.",
  "Implementing job rotation programs broadens employee skills and perspective.",
  "Offering language learning benefits improves cross-cultural communication.",
  "Using gamification in training increases engagement and knowledge retention.",
  "Providing mindfulness training reduces employee stress by 32%.",
  "Implementing a four-day workweek can increase productivity by 40%.",
  "Offering paternity leave improves gender equality and employee satisfaction.",
  "Creating quiet spaces in the office improves focus and productivity.",
  "Implementing a 'no meeting day' increases productivity and creativity.",
  "Providing financial wellness programs reduces employee stress by 25%.",
  "Offering unlimited paid time off can increase employee loyalty.",
  "Implementing a 'bring your pet to work' policy reduces stress levels.",
  "Creating a company book club fosters learning and team building.",
  "Offering on-site childcare reduces employee stress and absenteeism.",
  "Implementing a reverse mentoring program bridges generational gaps.",
  "Providing healthy snacks improves employee focus and energy levels.",
  "Creating a sustainability program increases employee engagement and satisfaction.",
  "Offering meditation sessions reduces workplace stress by 28%."
];