<template>
  <CFooter>
    <div>
      <i>{{ randomQuote }}</i>
    </div>
    <div class="ml-auto">
      <span>© {{new Date() | moment("YYYY")}} - <a :href="`https://${environmentHelpers.environment_website}`" target="_blank">{{environmentHelpers.environment_name}}</a> - {{$t('footer.All_rights_reserved')}}.</span>
    </div>
  </CFooter>
</template>

<script>
import quotes from '@/assets/quotes/quotes.js'

export default {
  name: 'TheFooter',
  data() {
    return {
      environmentTag: null,
      environmentHelpers: {
        environment_name: null,
        environment_website: null
      },
      randomQuote: '...',
    }
  },
  mounted: function() {
    if(localStorage.getItem('environmentTag') !== null) this.environmentTag = localStorage.getItem('environmentTag');
    if(localStorage.getItem('environmentHelpers') !== null) this.environmentHelpers = JSON.parse(localStorage.getItem('environmentHelpers'));
    
    this.getRandomQuote();
  },
  methods: {
    getRandomQuote() {
      const randomIndex = Math.floor(Math.random() * quotes.length);
      this.randomQuote = quotes[randomIndex];
    }
  },
  watch: {
    $route() {
      this.getRandomQuote();
    }
  }
}
</script>