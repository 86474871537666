<template>
  <CCard class="mb-0 points_update_card">
    <CCardBody class="p-0">
      <div class="d-flex align-items-center">
        <div class="update_subject_image">     
          <!-- Purchase Loyalty Store -->        
          <div v-if="update.points_type_tag === 'purchase_loyalty_store'" class="h-100 w-100">
            <div v-if="update.order.product_image_id && update.order.from_marketplace === 'N'" 
                class="h-100 w-100 subject_image"
                v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/loyalty-store-product/' + update.order.loyalty_store_product_id_external + '/' + update.order.product_image_id + '.jpg' + '/' + clientToken + ')' }">
            </div>
            <div v-else-if="update.order.product_image_id && update.order.from_marketplace === 'Y'" 
                class="h-100 w-100 subject_image"
                v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/loyalty-store-product-mp/' + update.order.loyalty_store_product_id_external + '/' + update.order.product_image_id + '.jpg' + '/' + clientToken + ')' }">
            </div>          
            <div v-else-if="update.order.loyalty_store_partner_product_external_id"             
                v-bind:style="{ backgroundImage: 'url(' + update.order.loyalty_store_partner_product_image_url + ')' }"
                class="h-100 w-100 subject_image">
            </div>
            <div v-else class="h-100 w-100 d-flex align-items-center justify-content-center subject_icon">          
              <i class="fa-solid" :class="update.points_type_icon_class"></i>
            </div>      
          </div>
          <!-- Learn Course -->
          <div v-else-if="update.points_type_tag === 'learn'" class="h-100 w-100">
            <div v-if="update.course.course_image_id && update.course.from_marketplace === 'N'" 
                v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/learn-course/' + update.course.lms_course_id_external + '/' + update.course.course_image_id + '.jpg' + '/' + clientToken + ')' }"
                class="h-100 w-100 subject_image">
            </div>
            <div v-else-if="update.course.course_image_id && update.course.from_marketplace === 'Y'" 
                v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/learn-course-mp/' + update.course.lms_course_id_external + '/' + update.course.course_image_id + '.jpg' + '/' + clientToken + ')' }"
                class="h-100 w-100 subject_image">
            </div>          
            <div v-else class="h-100 w-100 d-flex align-items-center justify-content-center subject_icon">          
              <i class="fa-solid" :class="update.points_type_icon_class"></i>
            </div>
          </div>
          <!-- Others -->
          <div v-else class="h-100 w-100">
            <div class="h-100 w-100 d-flex align-items-center justify-content-center subject_icon">          
              <i class="fa-solid" :class="update.points_type_icon_class"></i>
            </div>            
          </div>
        </div>
        <div class="flex-grow-1 p-2_5 pr-0 update_details">
          <div>
            <p v-line-clamp="1" class="title m-0">
              <span>{{update.spend_or_received_for}}</span>              
            </p>
          </div>
          <div class="d-flex flex-column meta">
            <div v-if="update.spend_or_received_for_subject" class="subject">
              <span v-line-clamp="1" v-html="update.spend_or_received_for_subject"></span>
            </div>
            <div v-if="update.received_at" class="received_at">
              <span>{{update.received_at | moment("DD-MM-YYYY HH:mm")}}</span>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-start points_update" v-bind:class="{ 'points_received' : update.change === 'increase', 'points_spend' : update.change === 'decrease' }">
          <img :src="apiBaseUrl + '/v1/common/cdn/file/image/base-app/credits/' + environmentTag + '_credit.png/' + clientToken"/>     
          <div class="ml-1 d-flex align-items-center">
            <span v-if="update.change === 'increase'">+</span>
            <span v-if="update.change === 'decrease'">-</span>
            <span>{{update.amount | abbr}}</span>
          </div>
        </div>      
      </div>
    </CCardBody>
  </CCard>
</template>

<script>

export default {
  name: 'loyaltyPointsUpdate',
  props: ['update'],
  filters: {
    abbr: function(points) {
      if(String(points).length >= 7) {
        if(points % 1 != 0) {
          return (points/1000000).toFixed(1) + 'M';
        } else {
          return (points/1000000) + 'M';
        }
      } else if(String(points).length >= 4) {
        if(points % 1 != 0) {
          return (points/1000).toFixed(1) + 'K';
        } else {
          return (points/1000) + 'K';
        }        
      } else {
        return points;
      }
    }
  },  
  data() {
    return {
      apiBaseUrl: null,
      clientToken: null,
      environmentTag: null
    }
  },
  mounted: function() {
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');

    if(localStorage.getItem('environmentTag')) this.environmentTag = localStorage.getItem('environmentTag');
  }
}
</script>