<template>
  <b-modal :can-cancel="['x']" :active.sync="showModal" :width="960" scroll="keep">
    <CCard v-if="modalData" class="mb-0">
      <CCardHeader class="pb-0">
        {{$t('core.Feature_unavailable')}}
      </CCardHeader>
      <CCardBody>
        <CRow v-if="modalData.license_name">
          <CCol cols="12" lg="12" class="pt-0 pb-0">
            <span>{{$t('core.Feature_unavailable_for_current_license', {license_name: modalData.license_name} )}}</span>
          </CCol>
        </CRow>    
      </CCardBody>
      <CCardFooter>        
        <CButton color="secondary" @click="closeModal()"><i class="fas fa-times mr-1"/>{{$t('close')}}</CButton>
      </CCardFooter>
    </CCard>
  </b-modal>
</template>

<script>
export default {
  name: 'FeatureUnavailableModal',  
  data () {
    return {
      showModal: false,
      modalData: null
    }
  },  
  methods: {
    closeModal() {
      this.showModal = false;
    }
  },
  mounted () {
    this.showModal = false;    
    
    this.$bus.$on('open_feature_unavailable_modal', (modal) => {
      if(this.showModal === false) this.showModal = true;
      this.modalData = modal.data;
    });

    this.$bus.$on('close_feature_unavailable_modal', () => {
      this.closeModal();
    });   
  }  
}
</script>