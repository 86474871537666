<template>
  <div>
    <div class="sidebar_container">
      <div class="sidebar_header">
        <CRow class="m-0">
          <CCol cols="10" lg="10" md="10" sm="10" class="text-left">
            <span v-if="history.length > 1" class="pointer" @click="removeSidebarHistoryStep()">
              <i class="fas fa-arrow-circle-left mr-1"/>
            </span>            
            <span class="sidebar_subject">
              {{!user.user_id_external ? $t('users.New_user') : $t('users.Edit_user') }}
            </span>
          </CCol>
          <CCol cols="2" lg="2" md="2" sm="2" class="text-right">
            <span @click="closeSidebarRight();"><i class="fas fa-times"/></span>        
          </CCol>
        </CRow>
      </div>
      <div class="sidebar_content">
        <CRow class="m-0">
          <CCol cols="6" lg="6" class="pt-0">
            <CInput type="text" :label="$t('users.Name')" class="mb-0" v-model="user.fullname" required was-validated/>
          </CCol>
          <CCol cols="6" lg="6" class="pt-0">
            <CInput type="email" :label="$t('users.Email')" class="mb-0" v-model="user.email" required was-validated :disabled="user.user_id_external"/>
          </CCol>          
        </CRow>
        <CRow class="m-0">
          <CCol cols="6" lg="6" class="pt-0">
            <label>{{ $t('users.Team') }}</label>
            <multiselect class="data_table"
                         v-bind:class="{'required' : !user.team, 'valid' : user.team}"
                         v-model="user.team"
                         :options="departments" 
                         :multiple="false"
                         :group-select="false"
                         group-values="teams" 
                         group-label="department_name"
                         :placeholder="$t('common.Select_team')" 
                         :selectLabel="$t('common.Add_team')" 
                         :selectedLabel="$t('Added')"
                         :deselectLabel="$t('common.Remove_team')"
                         track-by="team_id" 
                         label="team_name">
              <span slot="noResult">{{ $t('common.no_teams_found') }}</span>
            </multiselect>
          </CCol>
          <CCol cols="6" lg="6" class="pt-0">
            <CInput type="text" :label="$t('users.Function') + ' ' + $t('common.optional')" class="mb-0" v-model="user.user_function"/>
          </CCol>            
        </CRow>
        <CRow class="m-0">
          <CCol cols="6" lg="6" class="pt-0">
            <CInput type="date" :label="$t('users.Date_started') + ' ' + $t('common.optional')" class="mb-0" v-model="user.date_started"/>
          </CCol>
          <CCol cols="6" lg="6" class="pt-0">
            <CInput type="date" :label="$t('users.Date_of_birth') + ' ' + $t('common.optional')" class="mb-0" v-model="user.dob"/>
          </CCol>          
        </CRow>
        <CRow v-if="checkRole('super_admin') && !user.user_id_external" class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <CInput type="text" :label="$t('users.Password_new_employee') + ' ' + $t('common.optional')" v-model="user.password" class="m-0"/>
          </CCol>
        </CRow>
        <CRow v-if="user.user_id_external" class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <b-switch class="m-0 align-middle" v-model="user.is_active" size="is-small">{{ $t('users.User_activated') }}</b-switch>
          </CCol>
        </CRow>               

        <b-collapse class="panel show m-0" :open="false" position="is-bottom">
          <template #trigger="props">
            <span :aria-expanded="props.open">{{!props.open ? $t('common.Show_more') : $t('common.Show_less')}}</span>
          </template>
          <div class="panel-block p-0">
            <CRow class="w-100 m-0">
              <CCol cols="6" lg="6" class="pt-0">
                <CInput type="text" :label="$t('users.External_id') + ' ' + $t('common.optional')" class="mb-0" v-model="user.external_id"/>
              </CCol>
              <CCol cols="6" lg="6" class="pt-0">
                <CInput type="text" :label="$t('users.Phone') + ' ' + $t('common.optional')" class="mb-0" v-model="user.phone_number"/>
              </CCol>
            </CRow>
            <CRow class="w-100 m-0">
              <CCol cols="12" lg="12" class="pt-0">
                <label>{{ $t('common.Platform_roles') }}</label>
                <multiselect class="data_table"
                             v-model="user.platform_roles"
                             :options="userPlatformRoles" 
                             :multiple="true"
                             :placeholder="$t('common.Select_platform_role')" 
                             :selectLabel="$t('common.Set_platform_role')" 
                             :selectedLabel="$t('Selected')"
                             :deselectLabel="$t('common.Remove_platform_role')"
                             track-by="platform_role_tag" 
                             label="platform_role">
                  <span slot="noResult">{{ $t('common.No_platform_roles_found') }}</span>
                </multiselect>
              </CCol>
            </CRow>

            <CRow v-if="!user.company_auth_is_required && user.platform_roles.length > 0" class="w-100 m-0">
              <CCol cols="12" lg="12" class="pt-0">            
                <b-switch class="m-0" v-model="user.user_auth_is_required" size="is-small">{{ $t('auth.2fa_required_for_user') }}</b-switch>
              </CCol>
            </CRow>

            <CRow class="w-100 m-0">
              <CCol cols="6" lg="6" class="pt-0">
                <CInput type="date" :label="$t('users.Date_left') + ' ' + $t('common.optional')" class="mb-0" v-model="user.date_left"/>
              </CCol>
              <CCol cols="6" lg="6" class="pt-0">
                <label>{{ $t('users.Gender') }} {{$t('common.optional')}}</label>
                <CButtonGroup class="w-100">
                  <CButton color="outline-secondary" v-for="(value, name, key) in ['F', 'M', 'N', 'T', 'G']" :key="key" :pressed="value === user.gender ? true : false" @click="user.gender = value;">
                    <i v-if="value === 'M'" class="icon vertical m-0 fa-solid fa-mars"></i>
                    <i v-if="value === 'F'" class="icon vertical m-0 fa-solid fa-venus"></i>
                    <i v-if="value === 'G'" class="icon vertical m-0 fa-solid fa-genderless"></i>
                    <i v-if="value === 'T'" class="icon vertical m-0 fa-solid fa-transgender"></i>
                    <i v-if="value === 'N'" class="icon vertical m-0 fa-solid fa-neuter"></i>
                  </CButton>
                </CButtonGroup>
              </CCol>
            </CRow>

            <CRow class="w-100 m-0">
              <CCol cols="6" lg="6" class="pt-0">
                <CInput type="text" :label="$t('users.Work_location') + ' ' + $t('common.optional')" class="mb-0" v-model="user.work_location"/>
              </CCol>
              <CCol cols="6" lg="6" class="pt-0">
                <CInput type="text" :label="$t('users.Hours_on_contract') + ' ' + $t('common.optional')" class="mb-0" v-model="user.hours_on_contract"/>
              </CCol>
            </CRow>
            
            <CRow class="w-100 m-0">
              <CCol cols="6" lg="6" class="pt-0">
                <CInput type="text" :label="$t('users.Meyer_briggs') + ' ' + $t('common.optional')" class="mb-0" v-model="user.meyer_briggs"/>
              </CCol>
              <CCol cols="6" lg="6" class="pt-0">
                <CInput type="text" :label="$t('users.Disc') + ' ' + $t('common.optional')" class="mb-0" v-model="user.disc"/>
              </CCol>
            </CRow>
            
            <CRow class="w-100 m-0">
              <CCol cols="6" lg="6" class="pt-0">
                <CInput type="text" :label="$t('users.Office_based') + ' ' + $t('common.optional')" class="mb-0" v-model="user.office_based"/>
              </CCol>
              <CCol cols="6" lg="6" class="pt-0">
                <CInput type="text" :label="$t('users.Division') + ' ' + $t('common.optional')" class="mb-0" v-model="user.division"/>
              </CCol>
            </CRow>
            
            <CRow class="w-100 m-0">
              <CCol cols="6" lg="6" class="pt-0">
                <CInput type="text" :label="$t('users.Language') + ' ' + $t('common.optional')" class="mb-0" v-model="user.language"/>
              </CCol>
              <CCol cols="6" lg="6" class="pt-0">
                <CInput type="text" :label="$t('users.Persona_1') + ' ' + $t('common.optional')" class="mb-0" v-model="user.persona_1"/>
              </CCol>
            </CRow>
            
            <CRow class="w-100 m-0">
              <CCol cols="6" lg="6" class="pt-0">
                <CInput type="text" :label="$t('users.Persona_2') + ' ' + $t('common.optional')" class="mb-0" v-model="user.persona_2"/>
              </CCol>
              <CCol cols="6" lg="6" class="pt-0">
                <CInput type="text" :label="$t('users.Persona_3') + ' ' + $t('common.optional')" class="mb-0" v-model="user.persona_3"/>
              </CCol>                        
            </CRow>
          </div>
        </b-collapse>
      </div>
      <div class="sidebar_footer">
        <CRow class="m-0">
          <CCol cols="12" lg="12">
            <div v-if="user.user_id_external === null || user.user_id_external === undefined">
              <CButton color="primary" @click="insertUser();"><i class="fas fa-check mr-1"/>{{$t('Save')}}</CButton>
              <CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{$t('Dismiss')}}</CButton>
            </div>
            <div v-else>
              <CButton color="primary" @click="updateUser(user.user_id_external);"><i class="fas fa-check mr-1"/>{{$t('Update')}}</CButton>
              <CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{$t('Dismiss')}}</CButton>
            </div>     
          </CCol>
        </CRow>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect'

export default {
  name: 'userDetailsPane',
  props: ['history', 'userIdExternal', 'userTeam'],
  components: {
    Multiselect
  },
  watch: {
    $props: {
      handler() {
        this.getUserDetails();
      },
      deep: true,
      immediate: true,
    }
  },  
  data() {
    return {
      user: {
        platform_roles: []
      },
      departments: [],
      userPlatformRoles: [],
      platformRoles: []
    }
  },
  methods: {
    getUserDetails() {
      if(this.userIdExternal !== null) {
        axios.get(process.env.VUE_APP_API_URL + '/v1/core/user/' + this.userIdExternal)
        .then(res => {
          this.user = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });                  
      } else {
        // Reset the user
        this.resetUserData();
      }
    },
    insertUser() {
      let params = {};
      params = this.user;
      if(params.team) params.team_id = params.team.team_id;
      if(params.password === '') params.password = null;
      if(params.date_left === '') params.date_left = null;
      (params.user_auth_is_required === true) ? params.authentication_required = 1 : params.authentication_required = 0;
      (params.is_active === true) ? params.active = 'Y' : params.active = 'N';

      let name = params.fullname;
      let email = params.email;
      let team_id = params.team_id;
      let date_started = params.date_started;
      let date_left = params.date_left;

      if(name && email && team_id) {
        // Check if the date_left value is valid if set
        if(date_started && date_left && (Date.parse(date_started) > Date.parse(date_left))) {
          this.$buefy.toast.open({ message: this.$t('users.Date_left_must_be_later'), type: 'is-danger', duration: 2000 });
          return;
        }

        axios.post(process.env.VUE_APP_API_URL + '/v1/core/user', params)
        .then(res => {
          // Show a success notice
          this.$buefy.toast.open({ message: this.$t('users.User_added'), type: 'is-success', duration: 2000 });
          // Emit the update_team_employees event
          if(this.user && this.user.team) {           
            this.$bus.$emit('update_team_employees', {
              team_id: this.user.team.team_id,
              team_name: this.user.team.team_name
            });
          }
          // Emit the update_users event
          this.$bus.$emit('update_users');
          // Close the sidebar
          this.closeSidebarRight();
        })
        .catch(err => {
          // Show correct error message according to error
          if(err.response.data.error === 'License upgrade needed') {          
            this.$buefy.toast.open({ message: this.$t('users.Upgrade_license_for_another_user'), type: 'is-danger', duration: 2000 });
          } else if(err.response.data.error === 'User already exists') {          
            this.$buefy.toast.open({ message: this.$t('users.User_already_exists'), type: 'is-danger', duration: 2000 });
          } else { 
            this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
          }
        });
      } else {
        this.$buefy.toast.open({ message: this.$t('common.Empty_required_fields'), type: 'is-danger', duration: 2000 });
      }
    },
    updateUser(userIdExternal) {
      let params = {};
      params = this.user;
      params.team_id = params.team.team_id;
      (params.user_auth_is_required === true) ? params.authentication_required = 1 : params.authentication_required = 0;
      (params.is_active === true) ? params.active = 'Y' : params.active = 'N';

      if(params.date_left === '') {
        params.date_left = null;
        params.date_left_reset = true;
      } else {
        params.date_left_reset = false;
      }

      let name = params.fullname;

      if(name) {
        axios.put(process.env.VUE_APP_API_URL + '/v1/core/user/' + userIdExternal, params)
        .then(res => {
          let userIsActive = res.data.data.user_active;
          let dateLeftReset = res.data.data.date_left_reset;
          // Show a success notice
          this.$buefy.toast.open({ message: this.$t('users.User_updated'), type: 'is-success', duration: 2000 });
          // Check if the user is still active
          if(userIsActive) {
            // Check if the date left value has been resetted
            if(dateLeftReset) {
              // Emit the update_team_employees event
              if(this.user && this.user.team_id && this.user.team_name) {           
                this.$bus.$emit('update_team_employees', {
                  team_id: this.user.team_id,
                  team_name: this.user.team_name
                });
              }              
              // Emit the update_users event
              this.$bus.$emit('update_users');
            } else {
              // Emit the update_users event
              this.$bus.$emit('update_user_details', userIdExternal);
            }
          } else {
            // Emit the update_team_employees event
            if(this.user && this.user.team_id && this.user.team_name) {           
              this.$bus.$emit('update_team_employees', this.user);
            }
            // Emit the update_users event
            this.$bus.$emit('update_users');
          }

          // Close the sidebar
          this.closeSidebarRight();
        })
        .catch(err => {
          console.error(err);
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        });
      } else {
        this.$buefy.toast.open({ message: this.$t('common.Empty_required_fields'), type: 'is-danger', duration: 2000 });
      }
    },    
    getDepartments() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/organisation/departmentTeams')
      .then(res => {
        // Get departments and teams from API response        
        var departments = res.data.data.departments;
        var teams = res.data.data.teams;

        // Empty departments array
        this.departments = [];        

        // Loop through departments
        for(var d = 0; d < departments.length; d++) {
          // Push new department into departments array
          this.departments.push({
            department_id: departments[d].department_id,
            department_name: departments[d].department_name,
            teams: []
          });

          // Loop through teams
          for(var t = 0; t < teams.length; t++) {
            // If a team is part of a department, push the team into the teams array of this department
            if(departments[d].department_id === teams[t].department_id) {
              var index = this.departments.findIndex(i => i.department_id == departments[d].department_id);
              this.departments[index].teams.push({
                team_id: teams[t].team_id,
                team_name: teams[t].team_name  + ' (' + departments[d].department_name + ')'
              });
            }
          }
        }
      })
      .catch(err => {
        console.error(err); 
      });
    },       
    getUserPlatformRoles() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/roles/multiselect')
      .then(res => {
        this.userPlatformRoles = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getPlatformRoles() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/roles')
      .then(res => {      
        this.platformRoles = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },    
    checkRole(roleTag) {
      if(this.platformRoles.includes(roleTag)) {
        return true;
      } else{
        return false;
      }
    },    
    resetUserData () {
      this.user = {
        password: null,
        fullname: null,
        user_function: null,
        email: null,
        phone_number: null,
        dob: null,
        gender: null,
        team: null,
        work_location: null,
        external_id: null,
        date_started: null,
        date_left: null,
        platform_roles: []
      };

      if(this.userTeam !== null) {
         this.user.team = {
          team_id: this.userTeam.team_id,
          team_name: this.userTeam.team_name
        }
      }
    }, 
  },
  mounted: function(){
    this.getDepartments();
    this.getPlatformRoles();
    this.getUserPlatformRoles();
  }
}
</script>