<template>
  <CSidebar fixed 
            :minimize="false" 
            :show.sync="showSidebar" 
            :aside="true" 
            class="dashboard_sidebar_right" 
            v-bind:class="{ 'large' : ['loyalty_store_product_details'].includes(sidebarType),
                            'xlarge' : ['send_loyalty_moment', 'edit_loyalty_moment', 'new_loyalty_smart_moment', 'edit_loyalty_smart_moment'].includes(sidebarType)
                          }">

    <!------------>
    <!-- Common -->
    <!------------>

    <!-- Headcount Details -->
    <headcountDetails v-if="sidebarType === 'headcount_details'" :history="sidebarHistory" :headcountType="sidebarData.headcount_type" :headcountIdExternal="sidebarData.headcount_id_external"/>

    <!---------->
    <!-- Core -->
    <!---------->

    <!-- Organization - Department details -->
    <departmentDetails v-if="sidebarType === 'department'" :history="sidebarHistory" :departmentId="sidebarData.department_id"/>

    <!-- Organization - Team details -->
    <teamDetails v-if="sidebarType === 'team'" :history="sidebarHistory" :teamId="sidebarData.team_id" :departmentId="sidebarData.department_id" :departmentName="sidebarData.department_name"/>
    
    <!-- Organization - Team Profile -->
    <teamProfile v-if="sidebarType === 'team_profile'" :history="sidebarHistory" :teamId="sidebarData.team_id"/>
    
    <!-- Organization - User details -->
    <userDetails v-if="sidebarType === 'user'" :history="sidebarHistory" :userIdExternal="sidebarData.user_id_external" :userTeam="sidebarData.team"/>

    <!-- Organization - User Profile -->
    <userProfile v-if="sidebarType === 'user_profile'" :history="sidebarHistory" :userIdExternal="sidebarData.user_id_external"/>

    <!-- Target Group -->
    <targetGroup v-if="sidebarType === 'target_group'" :history="sidebarHistory" :groupIdExternal="sidebarData.group_id_external"/>

    <!-- Target Group Content -->
    <targetGroupContent v-if="sidebarType === 'target_group_content'" :history="sidebarHistory" :groupIdExternal="sidebarData.group_id_external"/>    

    <!-- Save email template -->
    <saveEmailTemplate v-if="sidebarType === 'save_email_template'" :history="sidebarHistory" :templateType="sidebarData.template_type" :templateSourceIdExternal="sidebarData.template_source_id_external" :productTag="sidebarData.product_tag"/>

    <!-- Add Auto-Moment -->
    <addProductAutoMoments v-if="sidebarType === 'add_product_auto_moments'" :history="sidebarHistory" :productTag="sidebarData.product_tag" :showInstructions="sidebarData.show_instructions"/>

    <!-- Auto-Moment Details -->
    <productAutoMomentDetails v-if="sidebarType === 'product_auto_moment_details'" :history="sidebarHistory" :eventTypeIdExternal="sidebarData.platform_event_type_id_external" :productTag="sidebarData.product_tag"/>

    <!-- Custom Auto-Moment -->
    <customProductAutoMoment v-if="sidebarType === 'custom_product_auto_moment'" :history="sidebarHistory" :productTag="sidebarData.product_tag"/>

    <!-- Route Details -->
    <routeDetails v-if="sidebarType === 'route_details'" :history="sidebarHistory" :routeIdExternal="sidebarData.route_id_external"/>

    <!------------->
    <!-- Connect -->
    <!------------->

    <!-- Signage Screen -->
    <signageScreen v-if="sidebarType === 'signage_screen' || sidebarType === 'duplicate_screen'" :history="sidebarHistory" :signageScreenIdExternal="sidebarData.signage_screen_id_external" :duplicateSignageScreen="sidebarData.duplicate_screen"/>

    <!-- Ticker Message -->
    <tickerMessage v-if="sidebarType === 'ticker_message'" :history="sidebarHistory" :tickerMessageIdExternal="sidebarData.signage_ticker_message_id_external"/>

    <!-- Topic Details -->
    <topicDetails v-if="sidebarType === 'topic_details'" :history="sidebarHistory" :topicIdExternal="sidebarData.topic_id_external"/>

    <!-- Subtopic Details -->
    <subtopicDetails v-if="sidebarType === 'subtopic_details'" :history="sidebarHistory" :subtopicIdExternal="sidebarData.subtopic_id_external" :topicIdExternal="sidebarData.topic_id_external"/>

    <!-- Flow Details -->
    <flowDetails v-if="sidebarType === 'flow_details'" :history="sidebarHistory" :swGroupIdExternal="sidebarData.sw_group_id_external"/> 

    <!-- Sender Details -->
    <senderDetails v-if="sidebarType === 'sender_details'" :history="sidebarHistory" :deliverSenderIdExternal="sidebarData.deliver_sender_id_external"/>

    <!-- Send Push Notification -->
    <sendPushNotification v-if="sidebarType === 'send_push_notification'" :history="sidebarHistory"/>

    <!-- Post Details  -->
    <swPostDetails v-if="sidebarType === 'sw_post_details'" :history="sidebarHistory" :postIdExternal="sidebarData.post_id_external" :scheduledForDatetime="sidebarData.scheduled_for_datetime" :backToPreview="sidebarData.back_to_preview"/>

    <!-- Post Preview  -->
    <swPostPreview v-if="sidebarType === 'sw_post_preview'" :history="sidebarHistory" :postIdExternal="sidebarData.post_id_external"/>

    <!-- Chat -->
    <connectChatDetails v-if="sidebarType === 'connect_chat_details'" :history="sidebarHistory" :threadIdExternal="sidebarData.thread_id_external"/>

    <!------------->
    <!-- Measure -->
    <!------------->

    <!-- New Target -->
    <newTarget v-if="sidebarType === 'new_target'" :history="sidebarHistory" :targetTypeTag="sidebarData.target_type_tag"/>

    <!-- Category Details -->
    <measureCategoryDetails v-if="sidebarType === 'sq_category_details'" :history="sidebarHistory" :sqCategoryIdExternal="sidebarData.sq_category_id_external"/>

    <!-- Subcategory Details -->
    <measureSubcategoryDetails v-if="sidebarType === 'sq_subcategory_details'" :history="sidebarHistory" :sqCategory="sidebarData.sq_category" :sqSubcategoryIdExternal="sidebarData.sq_subcategory_id_external"/>

    <!-- SatisQuestion Details -->
    <satisquestionDetails v-if="sidebarType === 'satisquestion_details'" :history="sidebarHistory" :sqSubcategory="sidebarData.sq_subcategory" :sqQuestionIdExternal="sidebarData.sq_question_id_external"/> 

    <!-- Burning Matter Details -->
    <burningMatterDetails v-if="sidebarType === 'burning_matter_details'" :history="sidebarHistory" :bmQuestionIdExternal="sidebarData.bm_question_id_external"/> 

    <!--------------->
    <!-- Knowledge -->
    <!--------------->

    <!-- Category Details -->
    <knowledgeCategoryDetails v-if="sidebarType === 'knowledge_category_details'" :history="sidebarHistory" :kbCategoryIdExternal="sidebarData.kb_intent_category_id_external"/>

    <!-- Subcategory Details -->
    <knowledgeSubcategoryDetails v-if="sidebarType === 'knowledge_subcategory_details'" :history="sidebarHistory" :kbCategory="sidebarData.kb_intent_category" :kbSubcategoryIdExternal="sidebarData.kb_intent_subcategory_id_external"/>

    <!-- Item Details -->
    <itemDetails v-if="sidebarType === 'knowledge_item_details'" :history="sidebarHistory" :kbSubcategory="sidebarData.kb_intent_subcategory" :kbIntentIdExternal="sidebarData.kb_intent_id_external" :backToPreview="sidebarData.back_to_preview"/>

    <!-- Item Preview -->
    <itemPreview v-if="sidebarType === 'knowledge_item_preview'" :history="sidebarHistory" :kbIntentIdExternal="sidebarData.kb_intent_id_external"/>

    <!----------->
    <!-- Learn -->
    <!----------->

    <!-- Category Details -->
    <learnCategoryDetails v-if="sidebarType === 'learn_category_details'" :history="sidebarHistory" :lmsCategoryIdExternal="sidebarData.lms_course_category_id_external"/>

    <!-- Course Details -->
    <learnCourseDetails v-if="sidebarType === 'learn_course_details'" :history="sidebarHistory" :lmsCategory="sidebarData.lms_category" :lmsCourseIdExternal="sidebarData.lms_course_id_external"/>

    <!-- Chapter Details -->
    <learnChapterDetails v-if="sidebarType === 'learn_chapter_details'" :history="sidebarHistory" :lmsCourse="sidebarData.lms_course" :lmsChapterIdExternal="sidebarData.lms_chapter_id_external"/>
    
    <!-- Step Details -->
    <learnStepDetails v-if="sidebarType === 'learn_step_details'" :history="sidebarHistory" :lmsCourse="sidebarData.lms_course" :lmsChapter="sidebarData.lms_chapter" :lmsStepIdExternal="sidebarData.lms_step_id_external"/>

    <!-- Assign Courses -->
    <assignCourses v-if="sidebarType === 'assign_courses'" :history="sidebarHistory" :lmsCourse="sidebarData.lms_course"/>

    <!-- Onboarding Package Details -->
    <onboardingPackageDetails v-if="sidebarType === 'onboarding_package_details'" :history="sidebarHistory" :onboardingPackageIdExternal="sidebarData.onboarding_package_id_external"/>

    <!-- Onboarding Package Content Details -->
    <onboardingPackageContentDetails v-if="sidebarType === 'onboarding_package_content_details'" :history="sidebarHistory" :onboardingPackage="sidebarData.onboarding_package" :onboardingPackageContentIdExternal="sidebarData.onboarding_package_content_id_external"/>

    <!------------->
    <!-- Loyalty -->
    <!------------->

    <!-- Category Details -->
    <loyaltyCategoryDetails v-if="sidebarType === 'loyalty_store_category_details'" :history="sidebarHistory" :loyaltyStoreCategoryIdExternal="sidebarData.loyalty_store_category_id_external"/>

    <!-- Product Details -->
    <loyaltyProductDetails v-if="sidebarType === 'loyalty_store_product_details'" :history="sidebarHistory" :loyaltyStoreCategory="sidebarData.loyalty_store_category" :loyaltyStoreProductIdExternal="sidebarData.loyalty_store_product_id_external"/>

    <!-- Grant points -->
    <grantPoints v-if="sidebarType === 'grant_points'" :history="sidebarHistory"/>

    <!-- Moment Template Details -->
    <loyaltyMomentTemplateDetails v-if="sidebarType === 'loyalty_moment_template_details'" :history="sidebarHistory" :momentIdExternal="sidebarData.loyalty_moment_id_external" :productTag="sidebarData.product_tag"/>

    <!-- Custom Moment Template Details -->
    <loyaltyMomentCustomTemplateDetails v-if="sidebarType === 'loyalty_moment_custom_template_details'" :history="sidebarHistory" :templateData="sidebarData.template_data" :productTag="sidebarData.product_tag"/>

    <!-- Benefits Moment Schedule Details -->
    <loyaltyBenefitsMomentScheduleDetails v-if="sidebarType === 'loyalty_benefits_moment_schedule_details'" :history="sidebarHistory"/>

    <!-- Send Loyalty Moment -->
    <sendLoyaltyMoment v-if="sidebarType === 'send_loyalty_moment'" 
                       :history="sidebarHistory" 
                       :modules="sidebarData.modules" 
                       :platformPermissions="sidebarData.platform_permissions" 
                       :momentTemplateData="sidebarData.moment_template_data"
                       :momentTemplateColorOptions="sidebarData.moment_template_color_options"
                       :momentTemplateColorMappings="sidebarData.moment_template_color_mappings"/>

    <!-- Edit Loyalty Moment -->
    <editLoyaltyMoment v-if="sidebarType === 'edit_loyalty_moment'" :history="sidebarHistory" :modules="sidebarData.modules" :platformPermissions="sidebarData.platform_permissions" :momentIdExternal="sidebarData.loyalty_moment_id_external"/>

    <!-- New Smart Moment -->
    <newLoyaltySmartMoment v-if="sidebarType === 'new_loyalty_smart_moment'" 
                          :history="sidebarHistory" 
                          :modules="sidebarData.modules" 
                          :platformPermissions="sidebarData.platform_permissions" 
                          :momentTemplateData="sidebarData.moment_template_data"
                          :momentTemplateColorOptions="sidebarData.moment_template_color_options"
                          :momentTemplateColorMappings="sidebarData.moment_template_color_mappings"/>

    <!-- Edit Smart Moment -->
    <editLoyaltySmartMoment v-if="sidebarType === 'edit_loyalty_smart_moment'" :history="sidebarHistory" :modules="sidebarData.modules" :platformPermissions="sidebarData.platform_permissions" :smartMomentIdExternal="sidebarData.loyalty_smart_moment_id_external"/>    

    <!--------->
    <!-- Hub -->
    <!--------->

    <!-- Resource Details -->
    <resourceDetails v-if="sidebarType === 'resource_details'" :history="sidebarHistory" :resourceIdExternal="sidebarData.resource_id_external"/>    

    <!------------->
    <!-- Service -->
    <!------------->

    <!-- Form Category Details -->
    <formCategoryDetails v-if="sidebarType === 'form_category_details'" :history="sidebarHistory" :formCategoryIdExternal="sidebarData.form_category_id_external"/>

    <!-- Form Submission Details -->
    <formSubmissionDetails v-if="sidebarType === 'form_submission_details'" :history="sidebarHistory" :formSubmissionIdExternal="sidebarData.form_submission_id_external"/>

    <!-- Question Folder Details -->
    <questionFolderDetails v-if="sidebarType === 'service_question_folder_details'" :history="sidebarHistory" :serviceQuestionFolderIdExternal="sidebarData.service_question_folder_id_external"/>

    <!----------------->
    <!-- Super Admin -->
    <!----------------->

    <!-- Company WKR sum details -->
    <companyWkrDetails v-if="sidebarType === 'company_wkr_details'" :history="sidebarHistory" :loyaltyCompanyWkrIdExternal="sidebarData.loyalty_company_wkr_id_external"/>    

    <!-- Marketplace Set Details -->
    <marketplaceSetDetails v-if="sidebarType === 'marketplace_set_details'" :history="sidebarHistory" :marketplaceSetIdExternal="sidebarData.marketplace_set_id_external" :productTag="sidebarData.product_tag"/>  

    <!-- Reset Employee Password -->
    <resetEmployeePassword v-if="sidebarType === 'reset_employee_password'" :history="sidebarHistory" :userIdExternal="sidebarData.user_id_external" :employeeName="sidebarData.employee_name"/>

    <!-- Default Platform Event Details -->
    <defaultPlatformEventDetails v-if="sidebarType === 'default_platform_event_details'" :history="sidebarHistory" :defaultEventConfigIdExternal="sidebarData.platform_default_event_config_id_external"/>    

  </CSidebar>
</template>

<script>
// Common
import headcountDetails from '@/sidebars/common/headcountDetails.vue';
// Core
import targetGroup from '@/sidebars/core/targetGroup.vue';
import targetGroupContent from '@/sidebars/core/targetGroupContent.vue';
import saveEmailTemplate from '@/sidebars/core/saveEmailTemplate.vue';
import addProductAutoMoments from '@/sidebars/core/addProductAutoMoments.vue';
import productAutoMomentDetails from '@/sidebars/core/productAutoMomentDetails.vue';
import customProductAutoMoment from '@/sidebars/core/customProductAutoMoment.vue';
import departmentDetails from '@/sidebars/core/organisation/departmentDetails.vue';
import teamDetails from '@/sidebars/core/organisation/teamDetails.vue';
import TeamProfile from '@/sidebars/core/organisation/TeamProfile.vue';
import userDetails from '@/sidebars/core/organisation/userDetails.vue';
import UserProfile from '@/sidebars/core/organisation/UserProfile.vue';
import routeDetails from '@/sidebars/core/routeDetails.vue';
// Connect
import signageScreen from '@/sidebars/connect/signageScreen.vue';
import tickerMessage from '@/sidebars/connect/tickerMessage.vue';
import topicDetails from '@/sidebars/connect/topicDetails.vue';
import subtopicDetails from '@/sidebars/connect/subtopicDetails.vue';
import flowDetails from '@/sidebars/connect/flowDetails.vue';
import senderDetails from '@/sidebars/connect/senderDetails.vue';
import sendPushNotification from '@/sidebars/connect/sendPushNotification.vue';
import swPostDetails from '@/sidebars/connect/swPostDetails.vue';
import swPostPreview from '@/sidebars/connect/swPostPreview.vue';
import connectChatDetails from '@/sidebars/connect/chatDetails.vue';
// Measure
import newTarget from '@/sidebars/measure/newTarget.vue';
import measureCategoryDetails from '@/sidebars/measure/categoryDetails.vue';
import measureSubcategoryDetails from '@/sidebars/measure/subcategoryDetails.vue';
import satisquestionDetails from '@/sidebars/measure/satisquestionDetails.vue';
import burningMatterDetails from '@/sidebars/measure/burningMatterDetails.vue';
// Knowledge
import knowledgeCategoryDetails from '@/sidebars/knowledge/categoryDetails.vue';
import knowledgeSubcategoryDetails from '@/sidebars/knowledge/subcategoryDetails.vue';
import itemDetails from '@/sidebars/knowledge/itemDetails.vue';
import itemPreview from '@/sidebars/knowledge/itemPreview.vue';
// Learn
import learnCategoryDetails from '@/sidebars/learn/categoryDetails.vue';
import learnCourseDetails from '@/sidebars/learn/courseDetails.vue';
import learnChapterDetails from '@/sidebars/learn/chapterDetails.vue';
import learnStepDetails from '@/sidebars/learn/stepDetails.vue';
import assignCourses from '@/sidebars/learn/assignCourses.vue';
import onboardingPackageDetails from '@/sidebars/learn/onboardingPackageDetails.vue';
import onboardingPackageContentDetails from '@/sidebars/learn/onboardingPackageContentDetails.vue';
// Loyalty
import loyaltyCategoryDetails from '@/sidebars/loyalty/categoryDetails.vue';
import loyaltyProductDetails from '@/sidebars/loyalty/productDetails.vue';
import loyaltyBenefitsMomentScheduleDetails from '@/sidebars/loyalty/benefitsMomentScheduleDetails.vue';
import grantPoints from '@/sidebars/loyalty/grantPoints.vue';
import loyaltyMomentTemplateDetails from '@/sidebars/loyalty/momentTemplateDetails.vue';
import loyaltyMomentCustomTemplateDetails from '@/sidebars/loyalty/customMomentTemplateDetails.vue';
import sendLoyaltyMoment from '@/sidebars/loyalty/sendMoment.vue';
import editLoyaltyMoment from '@/sidebars/loyalty/editMoment.vue';
import newLoyaltySmartMoment from '@/sidebars/loyalty/newSmartMoment.vue';
import editLoyaltySmartMoment from '@/sidebars/loyalty/editSmartMoment.vue';
// Hub
import resourceDetails from '@/sidebars/hub/resourceDetails.vue';
// Service
import formCategoryDetails from '@/sidebars/service/forms/categoryDetails.vue';
import formSubmissionDetails from '@/sidebars/service/forms/submissionDetails.vue';
import questionFolderDetails from '@/sidebars/service/questions/folderDetails.vue';
// Super Admin
import companyWkrDetails from '@/sidebars/admin/companyWkrDetails.vue';
import marketplaceSetDetails from '@/sidebars/admin/marketplaceSetDetails.vue';
import resetEmployeePassword from '@/sidebars/admin/resetEmployeePassword.vue';
import defaultPlatformEventDetails from '@/sidebars/admin/defaultPlatformEventDetails.vue';

export default {
  name: 'TheSidebar',
  components: {
    // Common
    headcountDetails,
    // Core
    targetGroup,
    targetGroupContent,
    saveEmailTemplate,
    addProductAutoMoments,
    productAutoMomentDetails,
    customProductAutoMoment,    
    departmentDetails,
    teamDetails,
    TeamProfile,
    userDetails,
    UserProfile,
    routeDetails,
    // Connect
    signageScreen,
    tickerMessage,
    topicDetails,
    subtopicDetails,
    flowDetails,
    senderDetails,
    sendPushNotification,
    swPostDetails,
    swPostPreview,
    connectChatDetails,
    // Measure
    newTarget,
    measureCategoryDetails,
    measureSubcategoryDetails,
    satisquestionDetails,
    burningMatterDetails,
    // Knowledge
    knowledgeCategoryDetails,
    knowledgeSubcategoryDetails,
    itemDetails,
    itemPreview,
    // Learn
    learnCategoryDetails,
    learnCourseDetails,
    learnChapterDetails,
    learnStepDetails,
    assignCourses,
    onboardingPackageDetails,
    onboardingPackageContentDetails,
    // Loyalty
    loyaltyCategoryDetails,
    loyaltyProductDetails,
    loyaltyBenefitsMomentScheduleDetails,
    loyaltyMomentTemplateDetails,
    loyaltyMomentCustomTemplateDetails,        
    grantPoints,
    sendLoyaltyMoment,
    editLoyaltyMoment,
    newLoyaltySmartMoment,
    editLoyaltySmartMoment,    
    // Hub
    resourceDetails,
    // Service
    formCategoryDetails,
    formSubmissionDetails,
    questionFolderDetails,
    // Super Admin
    companyWkrDetails,
    marketplaceSetDetails,
    resetEmployeePassword,
    defaultPlatformEventDetails
  },
  data () {
    return {
      showSidebar: false,
      sidebarType: null,
      sidebarData: null,
      sidebarHistory: []
    }
  },
  methods: {
    closeSidebarRight() {
      this.showSidebar = false;
      this.sidebarType = null;
      this.sidebarData = null;      
      this.sidebarHistory = [];
    },
    removeSidebarHistoryStep() {
      // Remove the last sidebar from the history
      this.sidebarHistory.pop();
      // Set a timeout to make sure the last sidebar is removed
      setTimeout(function() {
        let historySteps = this.sidebarHistory.length;
        // Get the type and data of the previous sidebar
        let previousSidebarType = this.sidebarHistory[historySteps - 1].type;
        let previousSidebarData = this.sidebarHistory[historySteps - 1].data;
        // Open the sidebar with this type and data
        this.sidebarType = previousSidebarType;
        this.sidebarData = previousSidebarData;
      }.bind(this), 100);     
    }
  },  
  mounted () {
    this.showSidebar = false;
    this.sidebarHistory = [];
    
    this.$bus.$on('open_sidebar_right', (sidebar) => {
      if(this.showSidebar === false) this.showSidebar = true;
      this.sidebarType = sidebar.type;
      this.sidebarData = sidebar.data;
      this.sidebarHistory.push({ type: sidebar.type, data: sidebar.data });
    });

    this.$bus.$on('close_sidebar_right', () => {
      this.closeSidebarRight();
    });
    
    this.$bus.$on('remove_sidebar_history_step', () => {
      this.removeSidebarHistoryStep();
    });     
  }
}
</script>
