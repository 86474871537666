<template>
  <b-modal :can-cancel="['x']" :active.sync="modalActive" :width="960" scroll="keep">
    <CCard class="mb-0">
      <CCardHeader class="pb-0">
        {{$t('core.Feature_unavailable')}}
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol cols="12" lg="12" class="pt-0 pb-0">
            <span>{{$t('core.Feature_unavailable_for_current_license')}}</span>
          </CCol>
        </CRow>    
      </CCardBody>
      <CCardFooter>        
        <CButton color="secondary" @click="closeModal"><i class="fas fa-times mr-1"/>{{$t('close')}}</CButton>
      </CCardFooter>
    </CCard>
  </b-modal>
</template>

<script>
export default {
  name: 'FeatureUnavailableModal',
  props: ['modalActive'],
  methods: {
    closeModal() {
      this.$emit('close');
    }
  }  
}
</script>