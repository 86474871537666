<template>
  <div class="store">
    <div class="sidebar_container">
      <div class="sidebar_header">
        <CRow class="m-0">
          <CCol cols="10" lg="10" md="10" sm="10" class="text-left pb-0">
            <span v-if="history.length > 1" class="pointer" @click="removeSidebarHistoryStep()">
              <i class="fas fa-arrow-circle-left mr-1"/>
            </span>            
            <span class="sidebar_subject">
							{{!loyaltyStoreProductIdExternal ? $t('store.New_product') : $t('store.Edit_product')}}
            </span>
          </CCol>
          <CCol cols="2" lg="2" md="2" sm="2" class="text-right pb-0">
            <span @click="closeSidebarRight();"><i class="fas fa-times"/></span>        
          </CCol>
        </CRow>
      </div>
      <div class="sidebar_content">
        <div v-if="platformPermissionsLoaded">      
          <div v-if="checkPermission('loyalty.store.externalproducts') && !loyaltyStoreProductIdExternal && !productTypeSelected" class="product_catalog">
            <CRow class="m-0">
              <CCol cols="3" lg="3">
                <CCard class="mb-0 pointer" @click="productTypeSelected = true">
                  <CCardBody>
                    <div class="text-center">
                      <div class="store_product_image mb-2">                
                        <img src="img/loyalty/product_image_fallback.png"/>
                      </div>
                      <div class="store_product_info">
                        <span>{{$t('loyalty.Add_custom_product')}}</span>
                      </div>
                    </div>
                  </CCardBody>
                </CCard>              
              </CCol>
            </CRow>

            <hr v-if="partnerProductsLoading === false" class="mt-0 mb-0">

            <div v-if="partnerProductsLoading === true">
              <CRow>
                <CCol cols="12" lg="12">
                  <loadingSpinner mode="auto" :content="$t('common.Loading')"/>
                </CCol>
              </CRow>            
            </div>
            <div v-else-if="partnerProductsLoading === false">
              <CRow class="m-0 product_filters">
                <CCol cols="3" lg="3" class="pb-0">
                  <label><b>{{$t('common.Search_for_products')}}</b></label>
                  <CInput type="text" class="mb-0 searchbar" v-model="productFilters.search_term" v-debounce:1s="getPartnerProducts"></CInput>
                </CCol>
                <CCol cols="3" lg="3" class="pb-0 locale-changer">
                  <label><b>{{$t('common.filter_by_country')}}</b></label>
                  <multiselect class="open_absolute"
                               v-model="productFilters.countries" 
                               :options="partnerProductsCountries" 
                               :multiple="false"
                               :hideSelected="false"
                               :closeOnSelect="true"
                               track-by="code" 
                               label="name"
                               :show-labels="false"
                               @input="getPartnerProducts();">
                    <span slot="noResult">{{$t('common.no_countries_found')}}</span>

                    <template slot="singleLabel" slot-scope="country">
                      <span v-if="country.option.flag" class="language_flag" v-html="getTwemoji(country.option.flag)"></span>
                      <span class="language_name">{{country.option.name}}</span>
                    </template>

                    <template slot="option" slot-scope="country">
                      <span v-if="country.option.flag" class="language_flag" v-html="getTwemoji(country.option.flag)"></span>
                      <span class="language_name">{{country.option.name}}</span>
                    </template>
                  </multiselect>
                </CCol>
                <CCol cols="3" lg="3" class="pb-0">
                  <label><b>{{$t('common.filter_by_currency')}}</b></label>
                  <multiselect class="open_absolute"
                               v-model="productFilters.currencies" 
                               :options="partnerProductsCurrencies" 
                               :multiple="false"
                               :hideSelected="false"
                               :closeOnSelect="true"
                               track-by="currency"
                               label="currency"
                               :show-labels="false"
                               @input="getPartnerProducts();">
                    <span slot="noResult">{{$t('common.no_currencies_found')}}</span>
                  </multiselect>
                </CCol>
                <CCol cols="3" lg="3" class="pb-0">
                  <label><b>{{$t('store.Filter_by_partner')}}</b></label>
                  <multiselect class="open_absolute"
                               v-model="productFilters.partners" 
                               :options="productPartners" 
                               :multiple="false"
                               :hideSelected="false"
                               :closeOnSelect="true"
                               track-by="loyalty_store_partner_tag" 
                               label="loyalty_store_partner_name"
                               :show-labels="false"
                               @input="getPartnerProducts();">
                    <span slot="noResult">{{$t('store.No_partners_found')}}</span>
                  </multiselect>
                </CCol>                
              </CRow>

              <div v-if="partnerProducts.length > 0">
                <CRow class="m-0">
                  <CCol cols="3" xl="3" lg="3" md="3" sm="3" v-for="product in partnerProducts" v-bind:key="product.external_id" class="pb-0">
                    <partnerProductCard :productData="product" @click.native="setProductValues(product); productTypeSelected = true;"/>
                  </CCol>
                </CRow>
                <CRow v-if="partnerProductsPaginated" class="m-0">
                  <CCol cols="12" md="12">                  
                    <v-pagination class="justify-content-end"
                                  v-model="partnerProductsCurrentPage"
                                  @input="onPartnerProductsPageChange"
                                  :length="partnerProductsPages"
                                  :total-visible="9"
                                  prev-icon="mdi-chevron-left"
                                  next-icon="mdi-chevron-right">
                    </v-pagination>
                  </CCol>
                </CRow>          
              </div>
              <div v-else>
                <CRow class="m-0">
                  <CCol cols="12" md="12">
                    {{$t('loyalty.No_products_based_on_filters')}}
                  </CCol>
                </CRow>              
              </div>
            </div>
          </div>

          <div v-else>
            <!-- Product name -->
            <CRow class="m-0">
              <CCol cols="12" lg="12">
                <label><b>{{$t('store.Product_name') + ' *'}}</b></label>
                <b-input v-model="product.product_name" :placeholder="$t('store.Enter_product_name')" type="text"></b-input>
              </CCol>           
            </CRow>
            
            <!-- Product category -->
            <CRow class="m-0">
              <CCol cols="12" lg="12" class="pt-0">
                <label><b>{{$t('common.Which_category') + ' *'}}</b></label>
                <multiselect class="data_table"
                             v-model="product.category"
                             :options="categories" 
                             :multiple="false"
                             :hide-selected="false"
                             :close-on-select="true"
                             :selectLabel="$t('common.Add_category')"
                             :deselectLabel="$t('common.Remove_category')"
                             track-by="loyalty_store_category_id_external" 
                             label="label">
                  <span slot="noResult">{{$t('common.no_categories_found')}}</span>
                </multiselect>
              </CCol>
            </CRow>
            
            <!-- Product description -->
            <CRow class="m-0">
              <CCol cols="12" lg="12" class="pt-0">                
                <label><b>{{$t('store.Product_description') + ' *'}}</b></label>
                <div class="wysiwyg">
                  <ckeditor @input="countdown();" :editor="editor" v-model="product.product_description" :config="editorConfig" :placeholder="$t('store.Product_description')"></ckeditor>  
                  <p class="countdown mt-1 mb-0" v-if="remainingCount.product_description > 0">{{remainingCount.product_description}} {{ $t('common.characters_remaining') }}</p>
                </div>
              </CCol>
            </CRow>

            <!-- Product score -->
            <CRow v-if="checkRole('super_admin')" class="m-0">
              <CCol cols="12" lg="12" class="pt-0">
                <label><b>{{$t('store.Product_weight')}}</b></label>
                <b-input v-model="product.product_score" :placeholder="$t('store.Enter_product_weight')" type="number" step="1" min="0"></b-input>
              </CCol>
            </CRow>

            <!-- Product details url -->
            <!-- <CRow class="m-0">
              <CCol cols="12" lg="12" class="pt-0">
                <label><b>{{$t('store.Product_details_url')}}</b></label>                
                <b-input v-model="product.product_details_url" :placeholder="$t('store.Enter_product_details_url')" type="text"></b-input>
              </CCol>
            </CRow> -->

            <!-- Product image -->
            <CRow v-if="product.loyalty_store_partner_product_external_id && !product.product_image_id && !product.image" class="m-0"> 
              <CCol cols="12" lg="12" class="pt-0">
                <label><b>{{$t('loyalty.Product_image')}}</b></label>
                <img v-if="!loyaltyStoreProductIdExternal" :src="product.loyalty_store_partner_product_image_url" class="current_image" onError="this.onerror=null; this.src='img/loyalty/product_image_fallback.png';"/>
                <img v-else :src="product.loyalty_store_partner_product_image_url" class="current_image" onError="this.onerror=null; this.src='img/loyalty/product_image_fallback.png';"/>
              </CCol> 
            </CRow>
            <CRow class="m-0">
              <CCol cols="6" lg="6" class="pt-0">
                <label><b>{{$t('store.Upload_an_image_for_product')}}</b></label>
                <image-uploader class="image_upload" :preview="true" :className="['fileinput', { 'fileinput--loaded': productImageUploaded }]" capture="environment" :debug="0" doNotResize="gif" :autoRotate="true" outputFormat="verbose" @input="setProductImage">
                  <label for="fileInput" slot="upload-label" class="m-0">
                    <span class="upload-caption btn btn-primary m-0"><i class="fas fa-file-upload mr-1"/>{{productImageUploaded ? $t('common.Replace_image') : $t('common.Upload_image')}}</span>
                  </label>
                </image-uploader>
              </CCol>
              <CCol v-if="loyaltyStoreProductIdExternal && product.product_image_id" cols="6" lg="6" class="pt-0">
                <label><b>{{$t('common.Current_image')}}</b></label>
                <div class="current_image fit-content">
                  <img :src="apiBaseUrl + '/v1/common/cdn/file/image/loyalty-store-product/' + product.loyalty_store_product_id_external + '/' + product.product_image_id + '.jpg' + '/' + clientToken"/>
                  <i class="fa-solid fa-times" @click="removeProductImage()"/>
                </div>
              </CCol>                                               
            </CRow>

            <!-- Product currency -->
            <!-- <CRow v-if="product.loyalty_store_partner_product_external_id" class="m-0">
              <CCol cols="12" lg="12" class="pt-0">
                <label><b>{{$t('common.Currency')}}</b></label>
                <span class="d-block">{{product.loyalty_store_partner_product_currency}}</span>
              </CCol>
            </CRow> -->

            <!-- Product limitations status -->
            <!-- <CRow class="m-0">
              <CCol cols="12" lg="12" class="pt-0">
                <label class="d-block"><b>{{$t('store.Product_has_limitations')}}</b></label>                
                <b-switch v-model="product.product_limitations_are_active" 
                          @input="product.product_limitations_are_active && productLimitations.length === 0 ? addProductLimitation() : null" 
                          class="m-0 align-middle"
                          size="is-small">
                  <span>{{product.product_limitations_are_active ? $t('Yes') : $t('No')}}</span>
                </b-switch>
              </CCol>            
            </CRow> -->

            <!-- Product limitations -->
            <!-- <CRow class="m-0">
              <CCol cols="12" lg="12" class="pt-0">
                <div class="d-flex">
                  <div class="flex-grow-1">
                    <label><b>{{$t('store.Product_limitations')}}</b></label>
                  </div>
                  <div class="d-none">
                    <b-switch v-model="showInactiveLimitations" size="is-small" class="m-0" @input="getProductLimitations()">{{$t('store.Show_inactive_limitations')}}</b-switch>
                  </div>
                </div>
                <div v-if="productLimitations.length > 0" class="product_limitations product_options">
                  <div v-for="(limitation, index) in productLimitations" v-bind:key="index" class="limitation option">
                    <div class="d-flex align-items-end">
                      <div class="flex-grow-1 pr-2">
                        <label>{{$t('store.Limitation_value')}}</label>                        
                        <CInput type="number" step="1" min="0" v-model="limitation.limitation_value" class="m-0"/>
                      </div>                        
                      <div class="d-flex align-items-center option_text pr-2">                        
                        <span>{{$t('store.order(s)')}}</span>                        
                      </div>
                      <div class="flex-grow-1 pr-2">
                        <label>{{$t('store.Limitation_type')}}</label>                      
                        <multiselect class="data_table open_absolute"
                                    v-model="limitation.limitation_type" 
                                    :options="productLimitationTypes"
                                    :multiple="false"
                                    :hide-selected="true"
                                    :close-on-select="true"             
                                    :placeholder="$t('store.Select_limitation_type')" 
                                    :selectLabel="$t('store.Set_limitation_type')"
                                    track-by="loyalty_store_product_limitation_type_tag" 
                                    label="loyalty_store_product_limitation_type_name"
                                    @input="$forceUpdate()">
                        <span slot="noResult">{{ $t('common.no_types_found') }}</span>
                        <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                        </multiselect>
                      </div>                      
                      <div v-if="limitation.loyalty_store_product_limitation_id_external" class="d-flex option_active pr-2">
                        <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="limitation.is_active" size="is-small">{{ $t('is_active') }}</b-switch>
                      </div>
                      <div>
                        <CButton v-if="limitation.loyalty_store_product_limitation_id_external" color="secondary" class="m-0" @click="removeProductLimitation(limitation.loyalty_store_product_limitation_id_external)">
                          <i class="fas fa-times"/>
                        </CButton>
                        <CButton v-else color="secondary" class="m-0" @click="productLimitations.splice(index, 1);">
                          <i class="fas fa-times"/>
                        </CButton>                                            
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <span>{{$t('store.No_product_limitations_set')}}</span>
                </div>
                <div v-if="productLimitations.length === 0" class="mt-2">
                  <CButton color="primary" @click="addProductLimitation();">
                    <span><i class="fas fa-plus mr-1"/>{{$t('store.Add_new_limitation')}}</span>
                  </CButton>
                </div>
              </CCol>
            </CRow> -->

            <!-- Product stock status -->
            <!-- <CRow v-if="productVariations.length > 0" class="m-0">
              <CCol cols="12" lg="12" class="pt-0">
                <label class="d-block"><b>{{$t('store.Product_works_with_stock')}}</b></label>                
                <b-switch class="m-0 align-middle" v-model="product.product_stock_is_active" size="is-small">{{product.product_stock_is_active ? $t('Yes') : $t('No')}}</b-switch>
              </CCol>            
            </CRow> -->

            <!-- Product variations -->
            <CRow class="m-0">
              <CCol cols="12" lg="12" class="pt-0">
                <div class="d-flex">
                  <div class="flex-grow-1">
                    <label><b>{{$t('store.Product_variations') + ' *'}}</b></label>
                  </div>
                  <div v-if="loyaltyStoreProductIdExternal">
                    <b-switch v-model="showInactiveVariations" size="is-small" class="m-0" @input="getProductVariations()">{{$t('store.Show_inactive_variations')}}</b-switch>
                  </div>
                </div>
                <div v-if="productVariations.length > 0" class="product_variations product_options">
                  <div v-for="(variation, index) in productVariations" v-bind:key="index" class="variation option">
                    <div class="d-flex align-items-end">                   
                      <div class="flex-1 pr-2">
                        <label>{{$t('store.Variation_name')}}</label>                        
                        <b-input v-model="variation.variation_name" :placeholder="$t('store.Enter_variation_name')" type="text"></b-input>
                      </div>
                      <div class="flex-1 pr-2">
                        <label>
                          <span>{{$t('store.Variation_price')}}</span>
                          <span v-if="product.loyalty_store_partner_product_external_id && productVariationValues.length === 1">
                            ({{productVariationValues[0].price}} {{product.loyalty_store_partner_product_currency}})
                          </span>
                          <span v-if="product.loyalty_store_partner_product_external_id && productVariationValues.length > 1">
                            ({{productVariationValues[0].price}} - {{productVariationValues[productVariationValues.length - 1].price}} {{product.loyalty_store_partner_product_currency}})
                          </span>                        
                        </label>
                        <div v-if="product.loyalty_store_partner_product_external_id">
                          <div v-if="productVariationValues.length === 1">                            
                            <b-input v-model="variation.variation_price" :placeholder="$t('store.Enter_product_weight')" @input="setVariationPointsToRedeem(index)" type="number" step="5" min="0"></b-input>
                          </div>
                          <div v-else-if="productVariationValues.length > 1">
                            <multiselect class="open_absolute"
                                         v-model="variation.variation_price_object" 
                                         :options="productVariationValues"
                                         :placeholder="$t('store.Select_a_value')"
                                         :selectLabel="$t('store.Select_value')"                                       
                                         :multiple="false"
                                         :hideSelected="true"
                                         :closeOnSelect="true"
                                         track-by="price" 
                                         label="name"
                                         :show-labels="false"
                                         @input="setVariationPointsToRedeem(index)">
                              <span slot="noResult">{{$t('common.no_values_found')}}</span>

                              <template slot="singleLabel" slot-scope="variation">                                
                                <span v-html="variation.option.name"></span>
                              </template>

                              <template slot="option" slot-scope="variation">                                
                                <span v-html="variation.option.name"></span>
                              </template>
                            </multiselect>
                          </div>
                        </div>
                        <div v-else>                          
                          <b-input v-model="variation.variation_price" :placeholder="$t('store.Enter_variation_price')" type="text" maxlength="10"></b-input>
                        </div>
                      </div>
                      <div class="flex-1 pr-2">
                        <label>{{$t('store.Variation_sku')}}</label>                        
                        <b-input v-model="variation.variation_sku" :placeholder="$t('store.Enter_variation_sku')" type="text"></b-input>
                      </div>                      
                      <div class="flex-1 pr-2">
                        <label>{{$t('store.Variation_coins_to_redeem')}}</label>
                        <b-input v-model="variation.variation_points_to_redeem" :placeholder="$t('store.Enter_variation_coins_to_redeem')" type="number" step="5" min="0" max="10000"></b-input>
                      </div>
                      <div v-if="product.product_stock_is_active === true" class="flex-1 pr-2">
                        <label>{{$t('store.Variation_stock')}}</label>                        
                        <b-input v-model="variation.variation_stock" :placeholder="$t('store.Enter_variation_stock')" type="number" step="1" min="0"></b-input>
                      </div> 
                      <div v-if="variation.loyalty_store_product_variation_id_external" class="d-flex option_active">
                        <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="variation.is_active" size="is-small">{{ $t('is_active') }}</b-switch>
                      </div>
                      <div v-else>
                        <CButton color="secondary" class="m-0" @click="productVariations.splice(index, 1);">
                          <i class="fas fa-times"/>
                        </CButton>                    
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <span>{{$t('store.No_product_variations_set')}}</span>
                </div>
                <div v-if="['business', 'elite'].includes(productLicenses.loyalty_license_tag) || (productLicenses.loyalty_license_tag === 'explorer' && productVariations.length === 0)" class="mt-2">
                  <CButton color="primary" @click="addProductVariation();">
                    <span><i class="fas fa-plus mr-1"/>{{$t('store.Add_new_variation')}}</span>
                  </CButton>
                </div>
              </CCol>
            </CRow>

            <!-- Product delivery option -->
            <CRow class="m-0">
              <CCol cols="12" lg="12" class="pt-0">
                <label><b>{{$t('store.Product_delivery_option') + ' *'}}</b></label>
                <multiselect class="open_absolute"
                             v-model="product.product_delivery_option" 
                             :options="productDeliveryOptions" 
                             :multiple="false"
                             :hideSelected="false"
                             :closeOnSelect="true"
                             :disabled="product.product_delivery_option && product.product_delivery_option.loyalty_store_delivery_option_tag === 'instant'"
                             track-by="loyalty_store_delivery_option_tag" 
                             label="loyalty_store_delivery_option_name"
                             :show-labels="false">
                  <span slot="noResult">{{$t('store.No_delivery_options_found')}}</span>
                </multiselect>
              </CCol>
            </CRow>

            <!-- Product delivery notes -->
            <CRow class="m-0">
              <CCol cols="12" lg="12" class="pt-0">
                <div v-if="showDeliveryNotesField" class="mb-2">
                  <label><b>{{$t('store.Product_delivery_notes')}}</b></label>
                  <div class="wysiwyg">
                    <ckeditor @input="countdown();" :editor="editor" v-model="product.product_delivery_notes" :config="editorConfig" :placeholder="$t('store.Product_delivery_notes')"></ckeditor>
                    <p class="countdown mt-1 mb-0" v-if="remainingCount.product_delivery_notes > 0">{{remainingCount.product_delivery_notes}} {{ $t('common.characters_remaining') }}</p>
                  </div>
                </div>
                <CButton color="secondary" class="m-0" @click="toggleDeliveryNotesField()">
                  <span>{{showDeliveryNotesField ? $t('store.Hide_delivery_notes_field') : $t('store.Show_delivery_notes_field')}}</span>
                </CButton>
              </CCol>
            </CRow>

            <!-- Product active status -->
            <CRow class="m-0">           
              <CCol cols="12" lg="12" class="pt-0">
                <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="product.is_active" size="is-small">{{ $t('is_active') }}</b-switch>
              </CCol>            
            </CRow>
          </div>   
        </div>
      </div>
      <div class="sidebar_footer">
        <CRow class="m-0">
          <CCol cols="12" lg="12">
						<div v-if="!loyaltyStoreProductIdExternal">
							<CButton color="primary" @click="insertProduct(product);"><i class="fas fa-check mr-1"/>{{$t('Save')}}</CButton>
							<CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{$t('Dismiss')}}</CButton>
						</div> 						   
						<div v-else>
							<CButton color="primary" @click="updateProduct(product);"><i class="fas fa-check mr-1"/>{{$t('Save')}}</CButton>
							<CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{$t('Dismiss')}}</CButton>
          	</div>
          </CCol>
        </CRow>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue';
import Multiselect from 'vue-multiselect'
import QuickEdit from 'vue-quick-edit';
import ImageUploader from 'vue-image-upload-resize';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { UploadAdapter } from '@/components/upload/upload-adapter';

import partnerProductCard from '@/components/loyalty/partnerProductCard';

import FeatureUnavailableModal from "@/components/core/FeatureUnavailableModal.vue";

export default {
  name: 'categoryDetails',
  props: ['history', 'loyaltyStoreCategory', 'loyaltyStoreProductIdExternal'],
  components: {
    loadingSpinner,
    Multiselect,
    QuickEdit,
    ImageUploader,
    partnerProductCard,
    FeatureUnavailableModal
  },
  watch: {
    $props: {
      handler() {
        this.getPlatformRoles();
        this.getPlatformPermissions();
        
        if(this.loyaltyStoreProductIdExternal) {
          // Get the product details
          this.getProductDetails();
          // Get the product variations
          this.getProductVariations();
          // Get the product limitations
          this.getProductLimitations();          
        } else {
          // Reset the product data
          this.resetProductData();
        }
      },
      deep: true,
      immediate: true,
    }
  }, 
  data() {
    return {
      platformRoles: [],
      platformRolesLoaded: false,
      platformPermissions: [],
      platformPermissionsLoaded: false,      
      apiBaseUrl: null,
      clientToken: null,
      featureUnavailableModal: false,
      productLicenses: {
        loyalty_licence_tag: null
      },      
      categories: [],
			product: {},
      productVariations: [],
      productVariationValues: [],
      showDeliveryNotesField: false,
      showInactiveVariations: false,
      productLimitations: [],
      productLimitationTypes: [],      
      showInactiveLimitations: false,
      productImageUploaded: false,
      productDeliveryOptions: [],
      partnerProductsLoaded: false,
      partnerProductsLoading: false,
      productTypeSelected: false,
      partnerProductsAvailable: false,
      partnerProductsCatalog: [],
      partnerProducts: [],      
      partnerProductsCurrencies: [],
      partnerProductsCountries: [],
      productPartners: [],      
      partnerProductsTotal: 0,
      partnerProductsStartIndex: 0,
      partnerProductsPaginated: false,
      partnerProductsCurrentPage: 1,
      partnerProductsPerPage: 12,
      partnerProductsPages: 0,
      productFilters: {
        search_term: '',
        partners: [],
        currencies: [],
        countries: []
      },
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [ 'heading', '|', 'bold', 'italic', 'bulletedList', 'numberedList', 'undo', 'redo' ],
        extraPlugins: [this.wysiwygUpload],
        upload: { externalId: null, source: 'store' }        
      },
      maxCount: {
        product_description: 1000,
        product_description_short: 40,
        product_delivery_notes: 1000
      },
      remainingCount: {
        product_description: 1000,
        product_description_short: 40,
        product_delivery_notes: 1000
      }        
    }
  },  
  methods: {
    getProductDetails() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/loyalty/product/' + this.loyaltyStoreProductIdExternal)
      .then(res => {
        this.product = res.data.data;
        // Set the product description
        if(this.product.product_description === null) this.product.product_description = "";
        // Set the short product description
        if(this.product.product_description_short === null) this.product.product_description_short = "";        
        // Set the product delivery notes
        if(this.product.product_delivery_notes === null) this.product.product_delivery_notes = "";
        // Trigger countdown function to calculate remaining characters for content
        this.countdown();
      })
      .catch(err => {
        console.error(err); 
      });
    },
    insertProduct(data) {
      let params = {};
      params.productData = data;
      (params.productData.is_active == true) ? params.productData.active = 'Y' : params.productData.active = 'N';
      // (params.productData.product_limitations_are_active == true) ? params.productData.product_limitations_active = 'Y' : params.productData.product_limitations_active = 'N';
      (this.productLimitations.length > 0) ? params.productData.product_limitations_active = 'Y' : params.productData.product_limitations_active = 'N';
      (params.productData.product_stock_is_active == true) ? params.productData.product_stock_active = 'Y' : params.productData.product_stock_active = 'N'; 
      if(params.productData.product_description === "") params.productData.product_description = null;
      if(params.productData.product_description_short === "") params.productData.product_description_short = null;
      if(params.productData.product_delivery_notes === "") params.productData.product_delivery_notes = null;
      if(params.productData.product_details_url === "") params.productData.product_details_url = null;

      // Set the category_from_marketplace value
      params.productData.category_from_marketplace = this.loyaltyStoreCategory.from_marketplace;      
      
      // Set the product limitations
      params.productData.product_limitations = this.productLimitations;
      // Loop through the product limitations
      for(var l = 0; l < params.productData.product_limitations.length; l++) {
        // Set the active value for the limitation
        (params.productData.product_limitations[l].is_active == true) ? params.productData.product_limitations[l].active = 'Y' : params.productData.product_limitations[l].active = 'N';
      }

      // Set the product variations
      params.productData.product_variations = this.productVariations;
      // Loop through the product variations
      for(var v = 0; v < params.productData.product_variations.length; v++) {
        // Set the active value for the variation
        (params.productData.product_variations[v].is_active == true) ? params.productData.product_variations[v].active = 'Y' : params.productData.product_variations[v].active = 'N';
        // Set the variation_value value if a variation_price_object is available
        if(params.productData.product_variations[v].variation_price_object) {
          params.productData.product_variations[v].variation_price = params.productData.product_variations[v].variation_price_object.price;
        }
        // Reset the variatin_stock value if the product stock is disabled
        if(!params.productData.product_stock_is_active) {
          params.productData.product_variations[v].stock = null;
        }        
      }

      if(this.validateProduct(params) === true) {
        axios.post(process.env.VUE_APP_API_URL + '/v1/loyalty/product', params)
        .then(res => {
          this.$buefy.toast.open({ message: this.$t('store.Product_added'), type: 'is-success', duration: 2000 });
          // Emit the update_loyalty_category_products event
          if(this.loyaltyStoreCategory.loyalty_store_category_id_external && this.loyaltyStoreCategory.loyalty_store_category_name) {            
            this.$bus.$emit('update_loyalty_category_products', this.loyaltyStoreCategory);
          }
          // Reset the data
          this.resetProductData();
          // Close the sidebar
          this.closeSidebarRight();         
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        })    
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }
    },
    updateProduct(data) {
      let params = {}
      params.productData = data;      
      (params.productData.is_active == true) ? params.productData.active = 'Y' : params.productData.active = 'N';
      // (params.productData.product_limitations_are_active == true) ? params.productData.product_limitations_active = 'Y' : params.productData.product_limitations_active = 'N';
      (this.productLimitations.length > 0) ? params.productData.product_limitations_active = 'Y' : params.productData.product_limitations_active = 'N';
      (params.productData.product_stock_is_active == true) ? params.productData.product_stock_active = 'Y' : params.productData.product_stock_active = 'N';
      if(params.productData.product_description === "") params.productData.product_description = null;
      if(params.productData.product_description_short === "") params.productData.product_description_short = null;
      if(params.productData.product_delivery_notes === "") params.productData.product_delivery_notes = null;
      if(params.productData.product_details_url === "") params.productData.product_details_url = null;

      // Set the product limitations
      params.productData.product_limitations = this.productLimitations;
      // Loop through the product limitations
      for(var l = 0; l < params.productData.product_limitations.length; l++) {
        // Set the active value for the limitation
        (params.productData.product_limitations[l].is_active == true) ? params.productData.product_limitations[l].active = 'Y' : params.productData.product_limitations[l].active = 'N';
      }

      // Set the product variations
      params.productData.product_variations = this.productVariations;
      // Loop through the product variations
      for(var v = 0; v < params.productData.product_variations.length; v++) {
        // Set the active value for the variation
        (params.productData.product_variations[v].is_active == true) ? params.productData.product_variations[v].active = 'Y' : params.productData.product_variations[v].active = 'N';        
        // Set the variation_value value if a variation_price_object is available
        if(params.productData.product_variations[v].variation_price_object) {
          params.productData.product_variations[v].variation_price = params.productData.product_variations[v].variation_price_object.price;
        }
        // Reset the variatin_stock value if the product stock is disabled
        if(!params.productData.product_stock_is_active) {
          params.productData.product_variations[v].stock = null;
        }
      }      

      if(this.validateProduct(params) === true) {
        axios.put(process.env.VUE_APP_API_URL + '/v1/loyalty/product/' + this.loyaltyStoreProductIdExternal, params)
        .then(res => {
          this.$buefy.toast.open({ message: this.$t('store.Product_updated'), type: 'is-success', duration: 2000 });
          // Emit the update_loyalty_category_products event
          if(this.loyaltyStoreCategory.loyalty_store_category_id_external && this.loyaltyStoreCategory.loyalty_store_category_name) {            
            this.$bus.$emit('update_loyalty_category_products', this.loyaltyStoreCategory);
          }
          // Reset the data
          this.resetProductData();
          // Close the sidebar
          this.closeSidebarRight();  
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        })     
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }      
    },
    validateProduct(params) {
      let product_name = params.productData.product_name;
      let category = params.productData.category;
      let product_description = params.productData.product_description;
      let product_variations = params.productData.product_variations;
      let product_delivery_option = params.productData.product_delivery_option;
      let loyalty_store_partner_product_external_id = params.productData.loyalty_store_partner_product_external_id;
      let loyalty_store_partner_product_currency = params.productData.loyalty_store_partner_product_currency;
      let product_stock_active = params.productData.product_stock_active;      
      let product_limitations_active = params.productData.product_limitations_active;
      let product_limitations = params.productData.product_limitations;
      let inactiveProductVariations = 0;
                
      // Check if base information for the product is available
      if(product_name && category && product_description && product_variations.length > 0 && product_delivery_option) {
        // Loop through the product_variations
        for(var v = 0; v < product_variations.length; v++) {
          // Check if the variation value and variation points to redeem are set
          if(!product_variations[v].variation_name || 
             !product_variations[v].variation_price || 
             (product_variations[v].variation_points_to_redeem === null || product_variations[v].variation_points_to_redeem === '' || product_variations[v].variation_points_to_redeem === 'undefined') ||
             (loyalty_store_partner_product_external_id && parseInt(product_variations[v].variation_points_to_redeem) === 0) ||
             (product_stock_active === 'Y' && (product_variations[v].variation_stock === null || product_variations[v].variation_stock === '' || product_variations[v].variation_stock === 'undefined'))) {                        
            return false;            
          }
          // Increase the inactiveProductVariations count when a variation is inactive
          if(product_variations[v].is_active == false) inactiveProductVariations++;
          // Check if there are active product variations available
          if(inactiveProductVariations === product_variations.length) return false;        
        }

        // Check if the product limitations are activated
        if(product_limitations_active === 'Y') {
          // Loop through the product_limitations
          for(var l = 0; l < product_limitations.length; l++) {
            if(!product_limitations[l].limitation_value || !product_limitations[l].limitation_type) {
              return false;            
            }      
          }
        }

        // Check if the product is a partner product
        if(loyalty_store_partner_product_external_id) {
          // If so, check if the loyalty_store_partner_product_currency is available
          if(loyalty_store_partner_product_currency) {
            // Return true if the check is completed
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    getCategories() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/loyalty/categories/multiselect')
      .then(res => {
        this.categories = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getProductDeliveryOptions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/loyalty/products/delivery/options')
      .then(res => {
        this.productDeliveryOptions = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getProductPartners() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/loyalty/partners')
      .then(res => {
        this.productPartners = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getPartnerProductCurrencies() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/loyalty/partners/products/currencies')
      .then(res => {
        this.partnerProductsCurrencies = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getPartnerProductCountries() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/loyalty/partners/products/countries')
      .then(res => {
        this.partnerProductsCountries = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },           
    onPartnerProductsPageChange(page) {
      // Set the partnerProductsStartIndex value
      (page > 1) ? this.partnerProductsStartIndex = (page - 1) * this.partnerProductsPerPage : this.partnerProductsStartIndex = 0;
      // Get the partner products
      this.getPartnerProducts();
    },
    getPartnerProducts() {
      // Start the loader
      if(this.partnerProductsLoaded === false) this.partnerProductsLoading = true;
      // Set the params
      let params = {}; 
      params.productFilters = this.productFilters;
      
      // Get the partner products
      axios.post(process.env.VUE_APP_API_URL + '/v1/loyalty/partners/products/' + this.partnerProductsStartIndex, params)
      .then(res => {
        this.partnerProducts = [];
        // Update the partnerProductsTotal value
        this.partnerProductsTotal = res.data.data.total_partner_products;
        // Set the default currentTotal value
        let currentTotal = res.data.data.total_partner_products;
        // Update the currentTotal value
        if(currentTotal / this.partnerProductsPerPage > 1000) currentTotal = this.partnerProductsPerPage * 1000;
        // Add the partner products to the partnerProducts array
        this.partnerProducts = res.data.data.partner_products;        
        // Check if the pagination should be activated
        (this.partnerProductsTotal > this.partnerProductsPerPage) ? this.partnerProductsPaginated = true : this.partnerProductsPaginated = false;
        // Reset the partnerProductsPages value
        this.partnerProductsPages = 0;        
        // Define the number of items of the pagination
        for (let i = 0; i < currentTotal; i = i + this.partnerProductsPerPage) {
          this.partnerProductsPages++;
        }        
        // Update the partnerProductsLoaded value
        this.partnerProductsLoaded = true;
        // Stop the loader
        this.partnerProductsLoading = false;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    setProductValues(productData) {
      this.product = {
        loyalty_store_product_id_external: null,
        loyalty_store_partner_product_external_id: productData.external_id,
        loyalty_store_partner_product_image_url: productData.image_url ? productData.image_url : 'img/loyalty/product_image_fallback.png',
        loyalty_store_partner_product_currency: productData.currency,
        loyalty_store_partner_tag: productData.loyalty_store_partner_tag,
        product_name: productData.name,
        product_description: '',
        product_description_short: '',
        product_delivery_notes: '',
        product_delivery_option: {
          loyalty_store_delivery_option_tag: productData.loyalty_store_delivery_option_tag,
          loyalty_store_delivery_option_name: productData.loyalty_store_delivery_option_name
        },
        image: null,                
        is_active: true
      };

      this.productVariations = [];
      this.productVariationValues = productData.variations;            

      for(var v = 0; v < productData.variations.length; v++) {
        let variation = {
          variation_name: productData.variations[v].name,
          variation_price: productData.variations[v].price,
          variation_sku: productData.variations[v].sku,
          variation_points_to_redeem: (productData.variations[v].price * 10),
          variation_price_object: {
            name: productData.variations[v].name,
            price: productData.variations[v].price
          }
        }

        this.productVariations.push(variation);
      }

      if(this.loyaltyStoreCategory.loyalty_store_category_id_external && this.loyaltyStoreCategory.loyalty_store_category_name) {
        this.product.category = {
          loyalty_store_category_id_external: this.loyaltyStoreCategory.loyalty_store_category_id_external,
          label: this.loyaltyStoreCategory.loyalty_store_category_name
        }
      }        
    },
    getProductVariations() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/loyalty/product/' + this.loyaltyStoreProductIdExternal + '/variations')
      .then(res => {
        let productVariations = res.data.data.product_variations;
        let productVariationValues = res.data.data.product_variation_values;              

        if(productVariationValues.length > 1) {
          for(var v = 0; v < productVariations.length; v++) {
            let variationValueIndex = productVariationValues.findIndex(obj => obj.min === productVariations[v].variation_value);          
            // Set the variation_value_object
            if(variationValueIndex >= 0) productVariations[v].variation_value_object = productVariationValues[variationValueIndex];
          }
        }

        // Filter on active status if necessary
        if(this.showInactiveVariations === false) productVariations = productVariations.filter( i => ['Y'].includes( i.active ) );        

        this.productVariations = productVariations;
        this.productVariationValues = productVariationValues;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    addProductVariation() {
      this.productVariations.push({ 
        variation_name: null,
        variation_price: null,
        variation_points_to_redeem: null,
        variation_sku: null,
        variation_stock: null
      });
    },
    setVariationPointsToRedeem(index) {
      if(this.productVariations[index].variation_price_object) {
        this.productVariations[index].variation_points_to_redeem = (this.productVariations[index].variation_price_object.price * 10);
      } else {
        this.productVariations[index].variation_points_to_redeem = (this.productVariations[index].variation_price * 10);
      }
    },
    getProductLimitationTypes() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/loyalty/products/limitations/types')
      .then(res => {
        this.productLimitationTypes = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },    
    getProductLimitations() {      
      axios.get(process.env.VUE_APP_API_URL + '/v1/loyalty/product/' + this.loyaltyStoreProductIdExternal + '/limitations')
      .then(res => {
        this.productLimitations = res.data.data;      
        // Filter on active status if necessary
        // if(this.showInactiveLimitations === false) this.productLimitations = this.productLimitations.filter( i => ['Y'].includes( i.active ));
      })
      .catch(err => {
        console.error(err); 
      });
    },
    addProductLimitation() {
      this.productLimitations.push({ limitation_value: null, limitation_type: null });
    },
    removeProductLimitation(productLimitationIdExternal) {
      axios.delete(process.env.VUE_APP_API_URL + '/v1/loyalty/product/' + this.loyaltyStoreProductIdExternal + '/limitation/' + productLimitationIdExternal)
      .then(res => {
        this.getProductLimitations();
      })
      .catch(err => {
        console.error(err); 
      });
    },    
    setProductImage(output) {
      this.productImageUploaded = true;
      this.product.image = output.dataUrl;
    },
    removeProductImage() {
      this.product.product_image_id = null;
    },  
    resetProductData() {
      this.product = {
        loyalty_store_product_id_external: null,
        product_name: '',
        product_description: '',
        product_description_short: '',
        product_delivery_notes: '',
        product_variations: [],
        product_limitations: [],
        product_score: 1,
        points_to_redeem: 0,
        product_details_url: null,
        image: null,
        is_active: true
      };
      
      // Add the first variation to the product_variations array
      this.addProductVariation();

      if(this.loyaltyStoreCategory.loyalty_store_category_id_external && this.loyaltyStoreCategory.loyalty_store_category_name) {
        this.product.category = {
          loyalty_store_category_id_external: this.loyaltyStoreCategory.loyalty_store_category_id_external,
          label: this.loyaltyStoreCategory.loyalty_store_category_name
        }
      }      
    },
    toggleDeliveryNotesField() {
      (this.showDeliveryNotesField) ? this.showDeliveryNotesField = false : this.showDeliveryNotesField = true;
    },
    countdown() {
      if(this.product.product_description) this.remainingCount.product_description = this.maxCount.product_description - this.product.product_description.length;
      if(this.product.product_description_short) this.remainingCount.product_description_short = this.maxCount.product_description_short - this.product.product_description_short.length;
      if(this.product.product_delivery_notes) this.remainingCount.product_delivery_notes = this.maxCount.product_delivery_notes - this.product.product_delivery_notes.length;
    },       
    wysiwygUpload( editor ) {
      var args = editor.config._config.upload;
      editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
        return new UploadAdapter({ loader, args });
      };
    },
    getTwemoji(emoji) {
      return twemoji.parse(emoji);
    },
    getPlatformRoles() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/roles')
      .then(res => {      
        this.platformRoles = res.data.data;
        // Update the platformRolesLoaded value
        this.platformRolesLoaded = true;        
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkRole(roleTag) {
      if(this.platformRoles.includes(roleTag)) {
        return true;
      } else{
        return false;
      }
    },     
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
        // Check if the user has permission to use partner products
        if(this.checkPermission('loyalty.store.externalproducts')) {
          // Get the partner products
          this.getPartnerProducts();
          // Get the product partners
          this.getProductPartners();
          // Get the partner product currencies
          this.getPartnerProductCurrencies();
          // Get the partner product countries
          this.getPartnerProductCountries();
        }
      })
      .catch(err => {
        console.error(err); 
      });
    },    
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }    
  }, 
  async mounted() {
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');
    
    this.productLicenses = await this.fetchProduuctLicenseData();

    this.getCategories();
    this.getProductDeliveryOptions();
    this.getProductLimitationTypes();
  }  
}
</script>